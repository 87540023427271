import { useEffect, useRef } from 'react'
import groupBy from 'ramda/src/groupBy'
import { ScrollHash } from '@hooks/use-scroll-hash'
import { Category } from '@models/category'
import { Complement } from '@models/complement'
import { Product } from '@models/product'
import { isLastIndex } from '@utils/array/is-last-index'
import { compareByOrder } from '@utils/list/sort-by-prop'
import { ProductSection } from './product-section'

function NoSearchResults() {
  return (
    <div className="flex h-full justify-center pt-12 text-neutral-n4">
      Nada encontrado
    </div>
  )
}

type ProductListProps = {
  categories?: Category[]
  products?: Product[]
  allProducts?: Product[]
  complements?: Complement[]
}

export function ProductList({
  categories = [],
  products = [],
  allProducts,
  complements = [],
}: ProductListProps) {
  const containerRef = useRef(null)

  useEffect(() => {
    if (!containerRef.current) {
      return
    }

    const observer = new MutationObserver((mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          const triggerScrollEventToUpdateActiveHash = () =>
            window.dispatchEvent(new CustomEvent('scroll'))

          triggerScrollEventToUpdateActiveHash()
        }
      }
    })

    observer.observe(containerRef.current, { childList: true })

    return () => {
      observer.disconnect()
    }
  }, [])

  const noSearchResult = products.length === 0
  if (noSearchResult) {
    return <NoSearchResults />
  }

  const productsGroupedByCategoryName = groupBy(
    (p: Product) => p.category.name,
    products,
  )

  const scrollHashOffset = 10 // 35

  return (
    <div ref={containerRef}>
      {categories.map(({ name, order }, index) => (
        <ScrollHash
          key={name}
          hash={name}
          order={order}
          offset={scrollHashOffset}
          observable
        >
          <ProductSection
            title={name}
            products={
              productsGroupedByCategoryName[name]
                ?.reverse()
                ?.sort(compareByOrder) || []
            }
            dontDoAutoSort
            allProducts={allProducts}
            complements={complements}
            isLastCategory={isLastIndex(index, categories)}
            // style={
            //   !isLastIndex(index, categories)
            //     ? {}
            //     : { height: `calc(100dvh - ${scrollHashOffset}px)` }
            // }
          />
        </ScrollHash>
      ))}
    </div>
  )
}
