import mixpanel from 'mixpanel-browser'
import { Modal } from '@ui/modal'
import { useScrollHash, useWindowScrollPositions } from '@hooks/use-scroll-hash'
import type { Category } from '@models/category'
import { cn } from '@utils/styles'

type CategoryListModalProps = {
  categories: Category[]
  visible: boolean
  onClose: () => void
}

export function CategoryListModal({
  categories,
  visible,
  onClose,
}: CategoryListModalProps) {
  const { scrollTo } = useScrollHash()
  const { scrollY } = useWindowScrollPositions()

  const handleOnClick = (category: string) => {
    scrollTo(category)
    mixpanel.track('Escolheu uma categoria', { category })
    onClose()
  }

  return (
    <Modal
      className={cn(
        'z-1',
        !visible
          ? 'top-0'
          : `scrollYValue-${scrollY} top-[${
              scrollY <= 70 ? 130 : 70
            }px] h-[calc(100%-${scrollY <= 70 ? 130 : 70}px)]`,
      )}
      style={{
        top: `${!visible ? 0 : scrollY <= 70 ? 130 : 70}px`,
        height: `calc(100% - ${scrollY <= 70 ? 130 : 70}px)`,
      }}
      // className={cn(
      //   'z-1',
      //   !visible
      //     ? 'top-0'
      //     : `scrollYValue-${scrollY} top-[${
      //         scrollY <= 98 ? 171 : 98
      //       }px] h-[calc(100%-${scrollY <= 98 ? 171 : 98}px)]`
      // )}
      // style={{
      //   top: `${!visible ? 0 : scrollY <= 98 ? 171 : 98}px`,
      //   height: `calc(100% - ${scrollY <= 98 ? 171 : 98}px)`,
      // }}
      closeButtonHidden
      visible={visible}
      onClose={onClose}
      hideHeader
    >
      <div className="flex flex-col py-4">
        {categories.map(({ name, imageUrl }) => (
          <div
            key={name}
            className="flex items-center gap-4 border-b border-neutral-n10 bg-neutral-n12 p-4 last:border-0"
            onClick={() => handleOnClick(name)}
          >
            {!!imageUrl && (
              <img
                className="h-12 w-12 rounded-[4px]"
                src={imageUrl}
                alt="imagem da categoria"
              />
            )}

            <span className="font-medium text-neutral-n2">{name}</span>
          </div>
        ))}
      </div>
    </Modal>
  )
}
