import { cx } from 'cva'
import { Icon } from '@ui/icon'

const searchInputRightElementClassNames = {
  container: cx([
    'flex',
    'h-9',
    'w-9',
    'items-center',
    'justify-center',
    'rounded-br-lg',
    'rounded-tr-lg',
    'bg-neutral-n12',
  ]),
}

export function SearchIcon() {
  return (
    <div className={searchInputRightElementClassNames.container}>
      <Icon name="search" color="neutral-n4" />
    </div>
  )
}

type ClearIconButtonProps = {
  onClick: () => void
}

export function ClearIconButton({ onClick }: ClearIconButtonProps) {
  return (
    <button
      type="button"
      className={searchInputRightElementClassNames.container}
      onClick={onClick}
    >
      <Icon name="close" color="neutral-n4" />
    </button>
  )
}
