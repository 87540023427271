import type { ChangeEventHandler } from 'react'
import mixpanel from 'mixpanel-browser'
import { useFilters } from '@hooks/use-filters'
import { ClearIconButton, SearchIcon } from './search-input-icons'

export function SearchInput() {
  const { searchTerm, setSearchTerm } = useFilters()

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!searchTerm) {
      mixpanel.track('Buscou por produto')
    }
    setSearchTerm(e.target.value)
  }

  const handleClearClick = () => {
    mixpanel.track('Limpou a busca por produto')
    setSearchTerm('')
  }

  return (
    <div className="flex h-10 flex-1 rounded-lg border border-neutral-n8">
      <input
        className="font-nunito-sans flex-1 rounded-bl-lg rounded-tl-lg py-3 pl-3 pr-0 outline-none placeholder:text-neutral-n6"
        type="text"
        placeholder="O que você procura?"
        value={searchTerm}
        onChange={handleChange}
      />
      {!!searchTerm ? (
        <ClearIconButton onClick={handleClearClick} />
      ) : (
        <SearchIcon />
      )}
    </div>
  )
}
