import {
  HashRouter,
  Route,
  Routes as ReactRouterRoutes,
} from 'react-router-dom'
import { MenuRoute } from '@routes/menu/menu-route'

// import { SuggestionsRoute } from '@routes/suggestions/suggestions-route'

// TODO create nice error component
export function Routes() {
  return (
    <HashRouter>
      <ReactRouterRoutes>
        <Route index element={<MenuRoute />} />
        <Route path="*" element={<h1>Not found</h1>} />
        <Route path="/menu" element={<MenuRoute />} />
        {/* <Route path="/suggestions" element={<SuggestionsRoute />} /> */}
      </ReactRouterRoutes>
    </HashRouter>
  )
}
