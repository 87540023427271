import { useContext } from 'react'
import { filtersContext } from './context'

export function useFilters() {
  const value = useContext(filtersContext)

  if (!value) {
    throw Error(
      'useFilters should be used inside the context of FiltersProvider'
    )
  }

  return value
}
