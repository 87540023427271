export const allergenNames = [
  'almonds',
  'dyes',
  'eggs',
  'fish',
  'gluten',
  'lactose',
  'milk',
  'nuts',
  'shellfish',
  'soy',
] as const

export type AllergenName = (typeof allergenNames)[number]

export type Allergen = {
  name: AllergenName
  mayContainTraces: boolean
}
