import { AllergenName } from '@models/allergen'
import { WeekDayName } from '@models/week-days'

export const allergensMap = {
  almonds: 'Amêndoas',
  dyes: 'Corantes',
  eggs: 'Ovo',
  fish: 'Peixe',
  gluten: 'Glúten',
  lactose: 'Lactose',
  milk: 'Leite',
  nuts: 'Castanhas',
  shellfish: 'Crustáceos',
  soy: 'Soja',
}

export const weekDaysMap = {
  sunday: 'Domingo',
  monday: 'Segunda-feira',
  tuesday: 'Terça-feira',
  wednesday: 'Quarta-feira',
  thursday: 'Quinta-feira',
  friday: 'Sexta-feira',
  saturday: 'Sábado',
}

export const weekDaysShortMap = {
  sunday: 'dom',
  monday: 'seg',
  tuesday: 'ter',
  wednesday: 'qua',
  thursday: 'qui',
  friday: 'sex',
  saturday: 'sáb',
}


export const weekDaysIndex = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
}

const numbersMap: Record<number, string> = {
  1: 'Um',
  2: 'Dois',
  3: 'Três',
  4: 'Quatros',
  5: 'Cinco',
  6: 'Seis',
  7: 'Sete',
  8: 'Oito',
  9: 'Nove',
  10: 'Dez',
}

export const locale = {
  allergen: {
    translate: (allergen: AllergenName) => allergensMap[allergen],
  },
  weekDay: {
    translate: (weekDay: WeekDayName) => weekDaysMap[weekDay],
    translateShort: (weekDay: WeekDayName) => weekDaysShortMap[weekDay],
  },
  number: {
    toWord: (n: number) => numbersMap[n],
  },
}
