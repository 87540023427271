import { Complement } from '@/models/complement'
import { SuggestionsView } from '@routes/suggestions/suggestions-view'
import { Modal } from '@ui/modal'
import type { Product } from '@models/product'

type SuggestionsModalProps = {
  products?: Product[]
  complements?: Complement[]
  visible: boolean
  onClose?: () => void
}

export function SuggestionsModal({
  products,
  complements,
  visible,
  onClose,
}: SuggestionsModalProps) {
  return (
    <Modal
      className="z-40"
      title="Sugestões"
      labelCloseButton="Ver cardápio completo"
      visible={visible}
      onClose={onClose}
    >
      <SuggestionsView
        products={products}
        complements={complements}
        stopProductClick={false}
      />
    </Modal>
  )
}
