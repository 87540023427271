import { useEffect, useRef, cloneElement, useContext } from 'react'
import type { ReactElement } from 'react'
import { useFunctions } from '@hooks/use-functions'
import type { Hash } from './common-types'
import { ScrollHashContext } from './context'
import { scrollTo } from './utils'

type ScrollHashProps = {
  children: ReactElement
  hash: Hash
  observable?: boolean
  offset?: number
  order: number
  referenceElementSelector?: string
  scrollableElementSelector?: string
}

export function ScrollHash({
  children,
  hash,
  observable = false,
  offset = 0,
  order,
  referenceElementSelector = '',
  scrollableElementSelector = '',
}: ScrollHashProps) {
  const ref = useRef<HTMLElement>(null)
  const { addFunction, removeFunction } = useFunctions()
  const { addElement, removeElement, setOffset } = useContext(ScrollHashContext)

  // TODO: use only HTML/CSS (https://www.youtube.com/watch?v=EC9OgfV8QKw)?
  useEffect(() => {
    addFunction(hash, () => {
      if (!ref.current) {
        return
      }

      scrollTo({
        element: ref.current,
        referenceElement: document.body,
        scrollableElement: window,
        offset,
      })
    })

    return () => {
      removeFunction(hash)
    }
  }, [
    hash,
    offset,
    addFunction,
    removeFunction,
    referenceElementSelector,
    scrollableElementSelector,
  ])

  useEffect(() => {
    if (!observable || !ref.current) {
      return
    }

    addElement(hash, ref.current, order)
    setOffset(offset)

    return () => {
      removeElement(hash)
    }
  }, [hash, order, offset, observable, addElement, removeElement, setOffset])

  return cloneElement(children, { ref })
}
