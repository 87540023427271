import { IconName } from '@ui/icon'
import { AllergenName } from '@models/allergen'

const namesMap: Record<AllergenName, IconName> = {
  almonds: 'almonds',
  dyes: 'dyes',
  eggs: 'egg',
  fish: 'fish',
  gluten: 'gluten',
  lactose: 'lactose',
  milk: 'milk',
  nuts: 'nuts',
  shellfish: 'shrimp',
  soy: 'soy',
}

export const convertAllergenToIconName = (allergen: AllergenName): IconName =>
  namesMap[allergen]
