import { useContext } from 'react'
import { FunctionsContext } from './context'

export const useFunctions = () => {
  const values = useContext(FunctionsContext)

  if (!values) {
    throw new Error(
      'useFunctions should be used inside the context of FunctionsProvider'
    )
  }

  return values
}
