import { createContext, useCallback, useReducer } from 'react'
import type { ReactNode, Reducer } from 'react'
import dissoc from 'ramda/src/dissoc'
import type { AFunction } from './common-types'

type State = Record<string, AFunction>

type AddFunctionAction = {
  type: 'add-function'
  payload: {
    key: string
    fn: AFunction
  }
}

type RemoveFunctionAction = {
  type: 'remove-function'
  payload: string
}

type Action = AddFunctionAction | RemoveFunctionAction

const reducer: Reducer<State, Action> = (state, action) => {
  if (!action?.payload) {
    throw Error('`action` needs a `payload` property')
  }

  switch (action?.type) {
    case 'add-function': {
      return {
        ...state,
        [action.payload.key]: action.payload.fn,
      }
    }
    case 'remove-function': {
      const key = action.payload
      return dissoc(key, state)
    }
    default:
      throw Error(`Unknown action '${action}'`)
  }
}

type UseFunctionsContext = {
  addFunction: (key: string, fn: AFunction) => void
  getFunction: (key: string) => AFunction
  removeFunction: (key: string) => void
}

export const FunctionsContext = createContext<UseFunctionsContext>({
  addFunction: (key, fn) => {},
  getFunction: (key) => () => {},
  removeFunction: (key) => () => {},
})

type FunctionsProviderProps = {
  children: ReactNode
}

export function FunctionsProvider({ children }: FunctionsProviderProps) {
  const [state, dispatch] = useReducer(reducer, {})

  const addFunction = useCallback(
    (key: string, fn: AFunction) => {
      dispatch({ type: 'add-function', payload: { key, fn } })
    },
    [dispatch]
  )

  const removeFunction = useCallback(
    (key: string) => {
      dispatch({ type: 'remove-function', payload: key })
    },
    [dispatch]
  )

  const getFunction = useCallback((key: string) => state[key], [state])

  const value = {
    addFunction,
    getFunction,
    removeFunction,
  }

  return (
    <FunctionsContext.Provider value={value}>
      {children}
    </FunctionsContext.Provider>
  )
}
