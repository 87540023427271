import { useRef, useState } from 'react'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import mixpanel from 'mixpanel-browser'
import anyPass from 'ramda/src/anyPass'
import concat from 'ramda/src/concat'
import filter from 'ramda/src/filter'
import ifElse from 'ramda/src/ifElse'
import includes from 'ramda/src/includes'
import pipe from 'ramda/src/pipe'
import prop from 'ramda/src/prop'
import sortBy from 'ramda/src/sortBy'
import uniq from 'ramda/src/uniq'
import when from 'ramda/src/when'
import where from 'ramda/src/where'
import { useTimeout, useToggle } from 'usehooks-ts'
import { SuggestionsContainer } from '@routes/suggestions/suggestions-container'
import { Icon } from '@ui/icon'
import { Button } from '@ui/icon/button'
import { MoreOrdersStamp } from '@ui/icon/stamps/moreOrders'
import { PromotionStamp } from '@ui/icon/stamps/promotion'
import { useFilters } from '@hooks/use-filters'
import type { Allergen } from '@models/allergen'
import { Complement } from '@models/complement'
import type { Product, Stamps } from '@models/product'
import { isNilOrEmpty } from '@utils/logic/is-nil-or-empty'
import { getCategoriesFromProducts } from '@utils/models/get-categories-from-products'
import { trimAndLower } from '@utils/string/trim-and-lower'
import { cn } from '@utils/styles'
import { Filters } from './filters'
import { AllergensFilterModal } from './filters/allergens-filter-modal'
import { AllergensRestrictionFilterModal } from './filters/allergens-restriction-filter-modal'
import { StampsModal } from './filters/stamps-modal'
import { SuggestionsModal } from './filters/suggestions-modal'
import { modal, useVisibleModals } from './filters/use-visible-modals'
import { ProductDetailsModal } from './product-details/product-details-modal'
import { ProductList } from './product-list'

type ProductListProps = {
  products?: Product[]
  complements?: Complement[]
  showSuggestionButton?: boolean
  tryAutoOpenSuggestionContainer?: boolean
}

export function CategoriesAndProducts({
  products = [],
  complements = [],
  showSuggestionButton = false,
  tryAutoOpenSuggestionContainer = false,
}: ProductListProps) {
  const {
    searchTerm,
    allergens: allergensFilter,
    stamps,
    selectedProduct,
    selectProduct,
  } = useFilters()

  const { includes: includesModal, toggle } = useVisibleModals()
  const [isOpenDialogAllergens, setIsOpenDialogAllergens] = useState(false)
  const [showSuggestionContainer, toggleSuggestionContainer] = useToggle(false)
  const [hasStamp, setHasStamp] = useState(false)
  useTimeout(() => {
    const hasAnyStampsEnable = products.some(
      (x) =>
        !!x?.stamps?.promotion?.enable ||
        !!x?.stamps?.moreOrders?.enable ||
        !!x?.stamps?.express?.enable,
    )
    const hasAllergensEnable = products.some(
      (p) => !!p.allergens?.length && !!p.allergensEnable,
    )
    if (hasAllergensEnable) {
      setIsOpenDialogAllergens(true)
      // toggle(modal.allergensRestriction)
      // mixpanel.track('Abriu selecionar restrições por alérgeno automaticamente')
      mixpanel.track('Abriu modal Informar restrições automaticamente')
    }
    if (hasAnyStampsEnable) {
      setHasStamp(true)
      if (!hasAllergensEnable && tryAutoOpenSuggestionContainer) {
        toggleSuggestionContainer()
        // toggle(modal.suggestions)
        mixpanel.track('Abriu Sugestões automaticamente')
      }
    }
  }, 0) //500)

  const isTrue = () => true
  const isFalse = () => false
  const hasSearchTerm = () => !isNilOrEmpty(searchTerm)
  const hasAllergensSelected = () => !isNilOrEmpty(allergensFilter)
  const hasStampsSelected = () =>
    !isNilOrEmpty(stamps) &&
    (!!stamps?.promotionSelected ||
      !!stamps?.moreOrdersSelected ||
      !!stamps?.expressSelected ||
      !!stamps?.withoutStampSelected ||
      thereIsNoStampsFilter({} as Stamps))
  const thereIsNoSearchTerm = (_: string) => isNilOrEmpty(searchTerm)
  const thereIsNoAllergensFilter = (_: Allergen[]) =>
    isNilOrEmpty(allergensFilter)
  const thereIsNoStampsFilter = (_: Stamps) => {
    return (
      isNilOrEmpty(stamps) ||
      (!stamps?.promotionSelected &&
        !stamps?.moreOrdersSelected &&
        !stamps?.expressSelected &&
        !stamps?.withoutStampSelected)
    )
  }

  const hasStamps = (s: any) => {
    return !isNilOrEmpty(s)
  }

  const thereIsNoPromotionSelected = (_: Stamps) => !stamps?.promotionSelected
  const ifElseCheckPromotion = ifElse(
    thereIsNoPromotionSelected,
    isFalse,
    (stamp) => !!stamp?.promotion?.enable,
  )

  const thereIsNoMoreOrdersSelected = (_: Stamps) => !stamps?.moreOrdersSelected
  const ifElseCheckMoreOrders = ifElse(
    thereIsNoMoreOrdersSelected,
    isFalse,
    (stamp) => !!stamp?.moreOrders?.enable,
  )

  const thereIsNoExpressSelected = (_: Stamps) => !stamps?.expressSelected
  const ifElseCheckExpress = ifElse(
    thereIsNoExpressSelected,
    isFalse,
    (stamp) => !!stamp?.express?.enable,
  )

  const thereIsNoWithoutStampSelected = (_: Stamps) =>
    !stamps?.withoutStampSelected
  const ifElseCheckWithoutStamp = ifElse(
    thereIsNoWithoutStampSelected,
    isFalse,
    (stamp) =>
      isNilOrEmpty(stamp) ||
      (!stamp?.promotion?.enable &&
        !stamp?.moreOrders?.enable &&
        !stamp?.express?.enable),
  )
  const propsToFilterProducts = ({
    allergensEnable,
  }: {
    allergensEnable: boolean
  }) => ({
    name: ifElse(
      thereIsNoSearchTerm,
      isTrue,
      pipe(trimAndLower, includes(trimAndLower(searchTerm))),
    ),
    allergens: ifElse(thereIsNoAllergensFilter, isTrue, (allergensProp) => {
      // TODO be more clear why using this return early approach
      if (isNilOrEmpty(allergensProp) || !allergensEnable) {
        return true
      }

      const allergenNames = allergensProp.map((a) => a.name)

      const union = concat(allergenNames, allergensFilter)
      const distinct = uniq(union)
      return union.length === distinct.length
    }),
    stamps: ifElse(
      thereIsNoStampsFilter,
      isFalse,
      ifElse(
        hasStamps,
        anyPass([
          ifElseCheckPromotion,
          ifElseCheckMoreOrders,
          ifElseCheckExpress,
          ifElseCheckWithoutStamp,
        ]),
        ifElseCheckWithoutStamp,
      ),
    ),
  })

  const hasAllergensEnable = (p: Product) => !!p.allergensEnable

  const maybeFilteredProducts = when(
    anyPass([hasSearchTerm, hasAllergensSelected, hasStampsSelected]),
    filter(
      ifElse(
        hasAllergensEnable,
        where(propsToFilterProducts({ allergensEnable: true })),
        where(propsToFilterProducts({ allergensEnable: false })),
      ),
    ),
  )(
    products,
    // products.map((p) => {
    //   if (!!p.linkedComplements?.length) {
    //     const productExtraData = ProductExtraData(p, products, complements)
    //     if (!!productExtraData.complements.length) {
    //       const allergensOptions =
    //         productExtraData.complements
    //           .map((c) => c.options)
    //           .reduce((a, c) => a?.concat(c || []), [])
    //           ?.map((o) => o.allergens)
    //           ?.reduce((a, c) => a?.concat(c || []), []) || []
    //       const allergensUnion = concat(
    //         !!p.allergensEnable ? p.allergens || [] : [],
    //         allergensOptions
    //       )
    //       p.allergensEnable =
    //         !!p.allergensEnable ||
    //         !!productExtraData.complements.some(
    //           (c) =>
    //             !!c.required && !!c.options?.some((o) => !!o.allergensEnable)
    //         )
    //       p.allergens = uniq(allergensUnion)
    //     }
    //   }
    //   return p
    // })
  )

  const maybeFilteredCategories = pipe(
    getCategoriesFromProducts,
    sortBy(prop('order')),
  )(maybeFilteredProducts)

  const handleOnAllergensRestrictionModalClose = () => {
    toggle(modal.allergensRestriction)
    mixpanel.track('Fechou restrições por alérgeno')
    if (hasStamp) {
      toggleSuggestionContainer()
      // toggle(modal.suggestions)
      mixpanel.track('Abriu Sugestões automaticamente')
    }
  }

  const handleOnAllergensModalClose = () => {
    toggle(modal.allergens)
    mixpanel.track('Fechou Filtrar')
  }

  const handleOnSuggestionsModalModalClose = () => {
    toggle(modal.suggestions)
    mixpanel.track('Fechou Sugestões')
  }

  const handleOnStampsModalModalClose = () => {
    if (includesModal(modal.promotionStamp)) {
      toggle(modal.promotionStamp)
      mixpanel.track('Fechou Promoções')
    }
    if (includesModal(modal.moreOrdersStamp)) {
      toggle(modal.moreOrdersStamp)
      mixpanel.track('Fechou Os Mais Vendidos')
    }
  }

  return (
    <>
      {!!hasStamp && !!showSuggestionButton && (
        <button
          type="button"
          className="fixed bottom-0 min-h-[56px] w-full max-w-3xl bg-neutral-n12 font-bold text-primary-p-0"
          onClick={() => {
            toggleSuggestionContainer()
            // toggle(modal.suggestions)
            mixpanel.track('Clicou em Ver sugestões')
          }}
        >
          Ver sugestões
        </button>
      )}

      <Filters
        categories={maybeFilteredCategories}
        products={products}
        hidden={products.length === 0}
      />

      {!!hasStamp && (
        <>
          <StampsBar
            onClick={(modal: string) => {
              toggle(modal)
            }}
            products={products}
          />
          {/* <FilterStampsBar /> */}
        </>
      )}

      <ProductList
        categories={maybeFilteredCategories}
        products={maybeFilteredProducts}
        allProducts={products}
        complements={complements}
      />

      <ProductDetailsModal
        complements={complements}
        product={selectedProduct}
        allProducts={products}
        visible={!!selectedProduct}
        onClose={() => selectProduct(undefined)}
      />

      <SuggestionsContainer
        products={maybeFilteredProducts}
        complements={complements}
        visible={showSuggestionContainer}
        onClose={() => {
          toggleSuggestionContainer()
        }}
      />

      <SuggestionsModal
        products={products}
        complements={complements}
        visible={includesModal(modal.suggestions)}
        onClose={handleOnSuggestionsModalModalClose}
      />

      <StampsModal
        modalType={
          includesModal(modal.moreOrdersStamp)
            ? modal.moreOrdersStamp
            : modal.promotionStamp
        }
        products={products}
        complements={complements}
        visible={
          includesModal(modal.promotionStamp) ||
          includesModal(modal.moreOrdersStamp)
        }
        onClose={handleOnStampsModalModalClose}
      />

      <AllergensRestrictionFilterModal
        products={products}
        visible={includesModal(modal.allergensRestriction)}
        onClose={handleOnAllergensRestrictionModalClose}
      />

      <AllergensFilterModal
        products={products}
        visible={includesModal(modal.allergens)}
        onClose={handleOnAllergensModalClose}
      />

      <DialogAllergens
        isOpen={isOpenDialogAllergens}
        onClick={() => {
          setIsOpenDialogAllergens(false)
          // toggle(modal.allergensRestriction)
          toggle(modal.allergens)
        }}
        onClose={() => {
          setIsOpenDialogAllergens(false)
        }}
      />
    </>
  )
}

export function DialogAllergens({
  onClick = () => {},
  onClose = () => {},
  isOpen = false,
}: {
  onClick: Function
  onClose: Function
  isOpen: boolean
}) {
  const handleOnCloseClick = () => {
    onClose()
    mixpanel.track('Clicou em Pular informar restrição')
  }

  const handleOnClick = () => {
    onClick()
    mixpanel.track('Clicou em Informar restrição')
  }

  return (
    <Dialog open={isOpen}>
      {/* <DialogTrigger asChild>
        <Button iconLeft="star" sizeNumber={16} text="Dialog" />
      </DialogTrigger> */}
      <DialogContent className="max-sm:max-w-[342px]" hideTopCloseButton>
        <DialogHeader className="hidden">
          <DialogTitle></DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-1">
          <div className="flex flex-col items-center justify-center gap-2 px-4 pb-12 text-center">
            <Icon name="soup-off" color="neutral-n6" size={64} />
            <span className="text-xl font-bold text-neutral-n2">
              Deseja informar restrição alimentar?
            </span>
            <span className="text-base font-medium text-neutral-n6">
              Os itens que contenham as restrições informadas serão ocultados do
              cardápio.
            </span>
          </div>
        </div>
        <DialogFooter className="flex flex-row items-center justify-center gap-4 sm:justify-center">
          <DialogClose asChild>
            <Button
              className="w-36 max-sm:w-32"
              text="Pular"
              onClick={handleOnCloseClick}
            />
          </DialogClose>
          <Button
            className="w-36 max-sm:w-32"
            text="Informar"
            intent="primary"
            onClick={handleOnClick}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export function StampsBar({
  onClick = () => {},
  products = [],
}: {
  onClick: Function
  products?: Product[]
}) {
  // const { stamps, toggleStamps } = useFilters()
  const containerRef = useRef<HTMLDivElement | null>(null)
  // const handlePointerDown = (e: React.PointerEvent<HTMLDivElement>): void => {
  //   const isMobile = () => /Mobi|Android/i.test(navigator.userAgent)
  //   if (e.target && !isMobile()) {
  //     e.stopPropagation()
  //     ;(e.target as HTMLElement)?.click()
  //   }
  //   if (containerRef.current) {
  //     containerRef.current.setPointerCapture(e.pointerId)
  //   }
  // }
  const handlePointerMove = (e: React.PointerEvent<HTMLDivElement>): void => {
    if (containerRef.current) {
      containerRef.current.hasPointerCapture(e.pointerId) &&
        (containerRef.current.scrollLeft -= e.movementX)
    }
  }
  const handlePointerUp = (e: React.PointerEvent<HTMLDivElement>): void => {
    if (containerRef.current) {
      containerRef.current.releasePointerCapture(e.pointerId)
    }
  }

  return (
    <div
      ref={containerRef}
      className={cn(
        'flex h-[150px] gap-4 overflow-x-auto bg-neutral-n12 px-3 pb-4 pt-1 [-ms-overflow-style:none] [scrollbar-width:none] max-md:w-screen [&::-webkit-scrollbar]:hidden',
        // isDragging ? 'cursor-grabbing' : ''
      )}
      // onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
      onPointerLeave={handlePointerUp}
    >
      {products.some((x) => !!x?.stamps?.promotion?.enable) && (
        <div className="h-[118px] w-[300px]">
          <PromotionStamp
            className="cursor-pointer whitespace-nowrap"
            onClick={() => {
              mixpanel.track('Clicou em Promoções')
              onClick(modal.promotionStamp)
            }}
          />
        </div>
      )}
      {products.some((x) => !!x?.stamps?.moreOrders?.enable) && (
        <div className="h-[118px] w-[300px]">
          <MoreOrdersStamp
            className="cursor-pointer whitespace-nowrap"
            onClick={() => {
              mixpanel.track('Clicou em Os Mais Vendidos')
              onClick(modal.moreOrdersStamp)
            }}
          />
        </div>
      )}
    </div>
  )
}

export function FilterStampsBar() {
  const { stamps, toggleStamps } = useFilters()
  const containerRef = useRef<HTMLDivElement | null>(null)
  // const [isDragging, setIsDragging] = useState<boolean>(false)
  // const handlePointerDown = (e: React.PointerEvent<HTMLDivElement>): void => {
  //   const isMobile = () => /Mobi|Android/i.test(navigator.userAgent)
  //   if (e.target && !isMobile()) {
  //     e.stopPropagation()
  //     ;(e.target as HTMLElement)?.click()
  //   }
  //   // setIsDragging(true)
  //   if (containerRef.current) {
  //     containerRef.current.setPointerCapture(e.pointerId)
  //   }
  // }
  const handlePointerMove = (e: React.PointerEvent<HTMLDivElement>): void => {
    // if (!isDragging) return
    if (containerRef.current) {
      containerRef.current.hasPointerCapture(e.pointerId) &&
        (containerRef.current.scrollLeft -= e.movementX)
    }
  }
  const handlePointerUp = (e: React.PointerEvent<HTMLDivElement>): void => {
    if (containerRef.current) {
      containerRef.current.releasePointerCapture(e.pointerId)
    }
    // setIsDragging(false)
  }

  return (
    <div
      ref={containerRef}
      className={cn(
        'flex h-[150px] gap-4 overflow-x-auto bg-neutral-n12 px-3 pb-4 pt-1 [-ms-overflow-style:none] [scrollbar-width:none] max-md:w-screen [&::-webkit-scrollbar]:hidden',
        // isDragging ? 'cursor-grabbing' : ''
      )}
      // onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerUp={handlePointerUp}
      onPointerLeave={handlePointerUp}
    >
      <Button
        size="xsmall"
        className="whitespace-nowrap rounded-full border border-solid border-primary-p-0 p-2 text-sm"
        intent={
          Object.values(stamps).every((selected) => !!selected)
            ? 'primary'
            : 'tertiary'
        }
        text="Todos"
        onClick={() => {
          if (Object.values(stamps).some((selected) => !selected)) {
            stamps.promotionSelected = true
            stamps.moreOrdersSelected = true
            stamps.expressSelected = true
            stamps.withoutStampSelected = true
          } else {
            stamps.promotionSelected = false
            stamps.moreOrdersSelected = false
            stamps.expressSelected = false
            stamps.withoutStampSelected = false
          }
          toggleStamps(stamps)
        }}
      />
      {/* <div className="h-[118px] w-[300px]">
        <PromotionStamp className="whitespace-nowrap" />
      </div> */}
      <Button
        iconLeft="star"
        size="xsmall"
        sizeNumber={16}
        className="whitespace-nowrap rounded-full border border-solid border-primary-p-0 p-2 text-sm"
        intent={stamps?.promotionSelected ? 'primary' : 'tertiary'}
        text="Promoção"
        onClick={() => {
          stamps.promotionSelected = !stamps.promotionSelected
          toggleStamps(stamps)
        }}
      />
      <Button
        iconLeft="star"
        size="xsmall"
        sizeNumber={16}
        className="whitespace-nowrap rounded-full border border-solid border-primary-p-0 p-2 text-sm"
        intent={stamps?.moreOrdersSelected ? 'primary' : 'tertiary'}
        text="Mais Pedidos"
        onClick={() => {
          stamps.moreOrdersSelected = !stamps.moreOrdersSelected
          toggleStamps(stamps)
        }}
      />
      <Button
        iconLeft="star"
        size="xsmall"
        sizeNumber={16}
        className="whitespace-nowrap rounded-full border border-solid border-primary-p-0 p-2 text-sm"
        intent={stamps?.expressSelected ? 'primary' : 'tertiary'}
        text="Express"
        onClick={() => {
          stamps.expressSelected = !stamps.expressSelected
          toggleStamps(stamps)
        }}
      />
      <Button
        size="xsmall"
        className="whitespace-nowrap rounded-full border border-solid border-primary-p-0 p-2 text-sm"
        intent={stamps?.withoutStampSelected ? 'primary' : 'tertiary'}
        text="Sem selo"
        onClick={() => {
          stamps.withoutStampSelected = !stamps.withoutStampSelected
          toggleStamps(stamps)
        }}
      />
    </div>
  )
}
