import { isNil } from 'ramda'
import { ProductSection } from '@routes/menu/support-components/product-list/product-section'
import { Complement } from '@models/complement'
import type { Product } from '@models/product'

type SuggestionsViewProps = {
  products?: Product[]
  complements?: Complement[]
  stopProductClick?: boolean
}

export function SuggestionsView({
  products,
  complements,
  stopProductClick = true,
}: SuggestionsViewProps) {
  const productsPromotion = products?.filter(
    (x) => !!x.stamps?.promotion?.enable, //&& !!x.available
  )
  const productsMoreOrders = products
    ?.filter(
      (x) => !!x.stamps?.moreOrders?.enable, //&& !!x.available
    )
    .sort((a, b) => {
      return !isNil(a.stamps?.moreOrders?.order) &&
        !isNil(b.stamps?.moreOrders?.order)
        ? a.stamps?.moreOrders?.order! - b.stamps?.moreOrders?.order!
        : !isNil(a.stamps?.moreOrders?.order)
          ? -1
          : !isNil(b.stamps?.moreOrders?.order)
            ? 1
            : 0
    })
  const productsExpress = products?.filter(
    (x) => !!x.stamps?.express?.enable, //&& !!x.available
  )
  return (
    <div className="flex flex-col">
      <h1 className="flex justify-center bg-neutral-n12 px-8 py-3 text-center text-base font-medium text-neutral-n4">
        Confira algumas das melhores opções que separamos para você!
      </h1>
      {!!productsPromotion?.length && (
        <ProductSection
          title="Promoções"
          iconName="discount2"
          stopProductClick={stopProductClick}
          products={productsPromotion}
          allProducts={products}
          complements={complements}
          isFromSuggestion
        />
      )}
      {!!productsMoreOrders?.length && (
        <ProductSection
          title="Mais Pedidos"
          iconName="star"
          stopProductClick={stopProductClick}
          dontDoAutoSort
          products={productsMoreOrders}
          allProducts={products}
          complements={complements}
          isFromSuggestion
        />
      )}
      {!!productsExpress?.length && (
        <ProductSection
          title="Express (até 15 min)"
          iconName="clock-bolt"
          stopProductClick={stopProductClick}
          products={productsExpress}
          allProducts={products}
          complements={complements}
          isFromSuggestion
        />
      )}
    </div>
  )
}
