import type { ReactNode } from 'react'
import { StyledSticky } from '@ui/styled-sticky'
import { cn } from '@utils/styles'

type HeaderContainerProps = {
  children?: ReactNode
  className?: string
  sticky: boolean
}

function HeaderContainer({
  children = null,
  className = '',
  sticky,
}: HeaderContainerProps) {
  const commonClassNames = cn('h-[72px] bg-neutral-n12 flex items-center')

  return !!sticky ? (
    <StyledSticky
      className={cn(commonClassNames, 'flex items-center', className)}
      classNamePinned="shadow-header"
    >
      {children}
    </StyledSticky>
  ) : (
    <div className={cn(commonClassNames, className)}>{children}</div>
  )
}

type HeaderProps = {
  children?: ReactNode
  className?: string
  leftElement?: ReactNode
  rightElement?: ReactNode
  sticky?: boolean
  title?: string
}

export function Header({
  children = null,
  className = '',
  leftElement = null,
  rightElement = null,
  sticky = false,
  title = '',
}: HeaderProps) {
  return (
    <HeaderContainer className={className} sticky={sticky}>
      {leftElement}

      {!!title && (
        <h1
          className={cn(
            'flex-1 items-center text-center text-2xl text-neutral-n2',
            !leftElement ? 'pl-[72px]' : '',
            !rightElement ? 'pr-[72px]' : ''
          )}
        >
          {title}
        </h1>
      )}

      {rightElement}

      {children}
    </HeaderContainer>
  )
}
