import { useToggableItemsList } from '@hooks/use-toggable-items-list'

export const useVisibleModals = useToggableItemsList

export const modal = {
  category: 'category',
  allergens: 'allergens',
  allergensRestriction: 'allergensRestriction',
  qrcode: 'qrcode',
  storedetails: 'storedetails',
  suggestions: 'suggestions',
  promotionStamp: 'promotionStamp',
  moreOrdersStamp: 'moreOrdersStamp',
}
