import nth from 'ramda/src/nth'
import pipe from 'ramda/src/pipe'
import split from 'ramda/src/split'

export const extractCloudName = pipe(split('/'), nth(3))

export const extractPublicId = (url: string): string => {
  if (!url) {
    return ''
  }

  const CLOUDINARY_REGEX =
    /^.+\.cloudinary\.com\/(?:[^/]+\/)(?:(image|video|raw)\/)?(?:(upload|fetch|private|authenticated|sprite|facebook|twitter|youtube|vimeo)\/)?(?:(?:[^_/]+_[^,/]+,?)*\/)?(?:v(\d+|\w{1,2})\/)?([^.^\s]+)(?:\.(.+))?$/
  const parts = CLOUDINARY_REGEX.exec(url)

  return parts && parts.length > 2 ? parts[parts.length - 2] : url
}
