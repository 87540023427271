import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useQuery } from 'react-query'
import { MangoName } from '@ui/icon/mango-name'
import { Img } from '@ui/img'
import { Spinner } from '@ui/spinner'
import { Store } from '@models/store'
import { isNilOrEmpty } from '@utils/logic/is-nil-or-empty'
import { queryIds } from '@config/react-query'
import { api } from '@api'
import { CategoriesAndProducts } from './support-components/categories-and-products'
import { StoreInfo } from './support-components/store-info'

function Error() {
  return (
    <div className="flex h-screen w-screen items-center justify-center bg-neutral-n11">
      Oops! Ocorreu um erro inesperado. Tente novamente mais tarde
    </div>
  )
}

function Loading() {
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center bg-neutral-n11">
      {/* <Spinner color="primary-p-0" className="mb-4" />
      Preparando seu cardápio */}
      <LoadingChildren />
    </div>
  )
}

function LoadingChildren() {
  return (
    <>
      <Spinner color="primary-p-0" size={40} />
      <p className="text-base font-bold text-neutral-n4">
        Carregando informações
      </p>
    </>
  )
}

function EmptyList() {
  return (
    <div className="flex h-full justify-center px-8 pt-12 text-center text-neutral-n4">
      Em breve, diversas refeições pra você aqui
    </div>
  )
}

function ShowStoreDelayed({ store }: { store: Store }) {
  return (
    <div className="relative flex h-screen w-screen flex-col items-center justify-center gap-4 bg-neutral-n11 px-8">
      <figure className="overflow-hidden rounded-full">
        <Img
          src={store.imageUrl}
          alt="logo do estabelecimento"
          width={130}
          height={130}
        />
      </figure>
      <p className="mb-6 text-center text-2xl font-bold text-neutral-n0">
        {store.fullName}
      </p>
      <LoadingChildren />
      <MangoName className="absolute bottom-20" width={102} height={40} />
    </div>
  )
}

const Delayed = ({
  children,
  store,
  waitBeforeShow = 3000,
}: {
  children: JSX.Element
  store: Store
  waitBeforeShow?: number
}) => {
  const [isShown, setIsShown] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true)
    }, waitBeforeShow)
    return () => clearTimeout(timer)
  }, [waitBeforeShow])

  return isShown ? children : <ShowStoreDelayed store={store!} />
}

export function MenuRoute() {
  const { isLoading, data } = useQuery(queryIds.initalData, api.getAllData, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  })

  return !!isLoading ? (
    <Loading />
  ) : !data || !data.store ? (
    <Error />
  ) : (
    <Delayed store={data.store}>
      <>
        {!!data?.store?.fullName && (
          <Helmet>
            <title>{data.store.fullName} | Mango</title>
          </Helmet>
        )}
        <div className="mx-auto flex w-full max-w-3xl flex-col justify-start shadow-xl max-md:shadow-none">
          <StoreInfo store={data.store} />
          {isNilOrEmpty(data?.products) ? (
            <EmptyList />
          ) : (
            <CategoriesAndProducts
              products={data.products}
              complements={data.complements}
            />
          )}
        </div>
      </>
    </Delayed>
  )
}
