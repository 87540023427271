import { cx } from 'cva'
import { Card } from '@ui/card'
import { IconButton } from '@ui/icon/icon-button'
import { Modal } from '@ui/modal'
import { StaticMap } from '@ui/static-map'
import { Store } from '@models/store'
import { dayOfWeek } from '@models/week-days'
import { locale } from '@utils/locale'
import { addWhitespaceBetweenNumbersAndLetters } from '@utils/string/replace'
import { cn } from '@utils/styles'

type StoreDetailsModalProps = {
  visible: boolean
  store?: Store
  onClose: () => void
}

export function StoreDetailsModal({
  visible,
  store,
  onClose,
}: StoreDetailsModalProps) {
  const fullAddress = `${
    store?.unit?.street || store?.street
  }, ${addWhitespaceBetweenNumbersAndLetters(
    store?.unit?.addressNumber || store?.addressNumber || ''
  )} - ${store?.unit?.district || store?.district}, ${
    store?.unit?.city || store?.city
  } - ${store?.unit?.uf || store?.uf}, ${store?.unit?.cep || store?.cep}`
  const fullAddressWithComplement = `${fullAddress} ${
    store?.unit?.complement || store?.complement
      ? ' - ' + store?.unit?.complement || store?.complement
      : ''
  }`
  return (
    <Modal
      // className="top-[72px] h-[calc(100%-72px)] bg-primary-p-l100"
      className={cn(
        'z-20 bg-primary-p-l100',
        !visible ? 'top-0' : 'top-[72px] h-[calc(100%-72px)]'
      )}
      closeButtonHidden
      visible={visible}
      onClose={onClose}
      hideHeader
    >
      <div className="flex flex-col gap-2 p-4">
        <Card
          iconsSizeLeft={20}
          iconColorLeft="primary-p-0"
          iconNameLeft="building-store"
          title="Descrição"
          className="bg-neutral-n11"
          classNameTitle={cx('text-primary-p-0 font-bold text-xs')}
          hidden={!store?.about}
        >
          <div className="flex pt-4">
            <span className="text-sm font-medium text-neutral-n6">
              {store?.about}
            </span>
          </div>
        </Card>
        <Card
          iconsSizeLeft={20}
          iconColorLeft="primary-p-0"
          iconNameLeft="map-pin"
          title="Localização"
          className="bg-neutral-n11"
          classNameTitle={cx('text-primary-p-0 font-bold text-xs')}
          hidden={!store?.unit?.cep && !store?.cep}
        >
          <div className="flex flex-col gap-4 pt-4">
            <div
              className={cn(
                'flex h-32 items-center justify-center rounded-lg bg-neutral-n12'
              )}
            >
              <a
                href={`https://www.google.com.br/maps/place/${fullAddress.replaceAll(
                  ' ',
                  '+'
                )}`}
                target="_blank"
                rel="noreferrer"
              >
                <StaticMap
                  className="h-32 rounded-lg object-fill max-sm:object-none sm:w-[688px]"
                  staticMapHeight={128}
                  zoom={15}
                  center={{
                    lat: store?.unit?.lat || store?.lat || 0,
                    lng: store?.unit?.lng || store?.lng || 0,
                  }}
                  markers={[
                    {
                      lat: store?.unit?.lat || store?.lat || 0,
                      lng: store?.unit?.lng || store?.lng || 0,
                    },
                  ]}
                />
              </a>
            </div>
            <div className="flex items-center">
              <span className="text-sm font-medium text-neutral-n6">
                {fullAddressWithComplement}
              </span>
              <IconButton
                intent="tertiary"
                iconName="copy"
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(fullAddressWithComplement)
                }}
              />
            </div>
          </div>
        </Card>
        <Card
          iconsSizeLeft={20}
          iconColorLeft="primary-p-0"
          iconNameLeft="phone"
          title="Contato"
          className="bg-neutral-n11"
          classNameTitle={cx('text-primary-p-0 font-bold text-xs')}
          hidden={
            (!store?.unit?.contacts?.length ||
              !store?.unit?.contacts?.some((x) => !!x.phone)) &&
            (!store?.contacts?.length ||
              !store?.contacts?.some((x) => !!x.phone))
          }
        >
          <div className="flex flex-col gap-4 pt-4">
            {(store?.unit?.contacts || store?.contacts)
              ?.filter((x) => !!x.phone)
              ?.map((contact, index) => (
                <div
                  key={`contact-${index}`}
                  className="flex flex-row text-neutral-n6"
                >
                  <span className="h-4 w-36 text-xs font-medium">
                    {contact.type}
                  </span>
                  <span className="h-4 text-xs font-medium">
                    {contact.phone}
                  </span>
                </div>
              ))}
          </div>
        </Card>
        <Card
          iconsSizeLeft={20}
          iconColorLeft="primary-p-0"
          iconNameLeft="calendar-time"
          title="Horário de funcionamento"
          className="bg-neutral-n11"
          classNameTitle={cx('text-primary-p-0 font-bold text-xs')}
          hidden={
            (!store?.unit?.businessWeekDays?.length ||
              !store?.unit?.businessWeekDays?.some((x) => !!x.available)) &&
            (!store?.businessWeekDays?.length ||
              !store?.businessWeekDays?.some((x) => !!x.available))
          }
        >
          <div className="flex flex-col gap-2 pt-4">
            {(store?.unit?.businessWeekDays || store?.businessWeekDays)?.map(
              (weekDay, weekDayCardIndex) => (
                <div
                  key={`weekDay-${weekDayCardIndex}`}
                  className={cn(
                    'flex min-h-[32px] flex-row items-center rounded-lg bg-neutral-n12 pl-2 pt-2 text-neutral-n6',
                    dayOfWeek === weekDay.name &&
                      'bg-neutral-n6 text-neutral-n12'
                  )}
                >
                  <div className="flex flex-row gap-6">
                    <div className="w-6 text-xs font-medium">
                      {locale.weekDay.translateShort(weekDay.name)}
                    </div>
                    <div className="flex w-max flex-col">
                      {(!weekDay?.intervals?.length || !weekDay?.available) && (
                        <span className="basis-6 text-xs font-medium">
                          Fechado
                        </span>
                      )}
                      {!!weekDay?.available &&
                        weekDay?.intervals?.map((interval, interevalIndex) => (
                          <div
                            key={`interval-${interevalIndex}`}
                            className="basis-6 text-xs font-medium"
                          >
                            {interval.startHour}
                            {' - '}
                            {interval.endHour}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </Card>
      </div>
    </Modal>
  )
}
