import { env } from '@config/env'

export const StaticMap = ({
  className = '',
  staticMapWidth = 640,
  staticMapHeight = 640,
  center,
  zoom,
  markers = [],
}: {
  className?: string
  staticMapWidth?: number
  staticMapHeight?: number
  center: google.maps.LatLngLiteral
  zoom: number
  markers?: Array<google.maps.LatLngLiteral>
}) => {
  const generateStaticMapURL = () => {
    const markerParams = markers.map(
      (marker) => `markers=${marker.lat},${marker.lng}`
    )
    const mapParams = `center=${center.lat},${center.lng}&zoom=${zoom}&size=${staticMapWidth}x${staticMapHeight}`
    const apiKeyParam = `key=${env.google.apiKey}`

    const staticMapURL = `https://maps.googleapis.com/maps/api/staticmap?${mapParams}&${markerParams.join(
      '&'
    )}&${apiKeyParam}`
    return staticMapURL
  }

  return (
    <div>
      <img
        className={className}
        src={generateStaticMapURL()}
        alt="Static Map"
      />
    </div>
  )
}
