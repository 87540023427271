import mixpanel from 'mixpanel-browser'
import { ProductDetailsModal } from '@routes/menu/support-components/product-details/product-details-modal'
import { Header, HeaderIconButton } from '@ui/header'
import { Modal } from '@ui/modal'
import { useFilters } from '@hooks/use-filters'
import { Complement } from '@models/complement'
import { Product } from '@models/product'
import { cn } from '@utils/styles'
import { SuggestionsView } from './suggestions-view'

type SuggestionsContainerProps = {
  products?: Product[]
  complements?: Complement[]
  visible?: boolean
  onClose: () => void
}
export function SuggestionsContainer({
  products,
  complements,
  visible = true,
  onClose,
}: SuggestionsContainerProps) {
  const { selectedProduct, selectProduct } = useFilters()
  return (
    <>
      <div
        className={cn(
          'fixed inset-x-0 z-30 m-auto flex h-screen max-w-3xl flex-col overflow-y-auto bg-neutral-n11 shadow-xl max-md:shadow-none',
          visible
            ? 'trackable-purpose-modal-open-class visible top-0'
            : 'invisible bottom-0',
        )}
      >
        <div
          className="flex-1 overflow-y-auto"
          onScrollCapture={(e) => {
            const target = e.target as any
            const position = target.scrollTop
            const trigger = position % 70 === 0
            if (trigger) mixpanel.track('Moveu o scroll em Sugestões')
          }}
        >
          <Header
            title="Sugestões"
            rightElement={
              <HeaderIconButton
                iconName="close"
                color="neutral-n6"
                onClick={() => {
                  onClose?.()
                  mixpanel.track('Clicou no X para fechar Sugestões')
                }}
              />
            }
            sticky
          />

          <SuggestionsView
            products={products}
            stopProductClick={false}
            complements={complements}
          />
        </div>

        <button
          type="button"
          className="min-h-[56px] bg-neutral-n12 font-bold text-primary-p-0"
          onClick={() => {
            onClose?.()
            mixpanel.track('Clicou em Ver cardápio completo')
          }}
        >
          Ver cardápio completo
        </button>
      </div>

      <ProductDetailsModal
        complements={complements}
        product={selectedProduct}
        visible={!!selectedProduct}
        allProducts={products}
        onClose={() => selectProduct(undefined)}
      />
    </>
  )
}

export function SuggestionsModal({
  products,
  complements,
  visible,
  onClose,
}: SuggestionsContainerProps) {
  return (
    <Modal title=" " className="bg-neutral-n12" visible={visible}>
      <SuggestionsContainer
        products={products}
        complements={complements}
        visible={visible}
        onClose={onClose}
      />
    </Modal>
  )
}
