import type { HTMLProps } from 'react'

type LactoseProps = HTMLProps<SVGSVGElement>

export function Lactose({
  color = '#000',
  width = 24,
  height = 24,
  size = 0,
  ...otherProps
}: LactoseProps) {
  return (
    <svg
      width={size || width}
      height={size || height}
      fill="none"
      viewBox="0 0 96 96"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <rect width="98.4249" height="94.5079" fill="none" />
      <path
        d="M35.5026 28V12L19.8342 12.0001V24.755C19.8342 26.8327 19.0088 28.8254 17.5396 30.2946L14.2946 33.5396C12.8254 35.0088 12 37.0015 12 39.0792L12 74.6737C12 79.0004 15.5075 82.5079 19.8342 82.5079H23.7513"
        stroke={color}
        strokeWidth="7.8342"
        strokeLinejoin="round"
      />
      <path
        d="M35.5026 59.0052C35.5026 47.254 47.2539 35.5027 59.0052 35.5027L86.4249 59.0053M35.5026 59.0052L86.4249 59.0053M35.5026 59.0052C35.5026 65.921 35.5026 82.5079 35.5026 82.5079C52.4767 82.5079 69.4508 82.5079 86.4249 82.5079L86.4249 59.0053"
        stroke={color}
        strokeWidth="7.8342"
        strokeLinejoin="round"
      />
      <circle cx="47.0052" cy="70.5078" r="3.9171" fill={color} />
      <circle cx="50.9223" cy="47.0052" r="3.9171" fill={color} />
      <circle cx="66.5907" cy="70.5078" r="3.9171" fill={color} />
      <line
        x1="11.7513"
        y1="50.9223"
        x2="23.5026"
        y2="50.9223"
        stroke={color}
        strokeWidth="7.8342"
      />
    </svg>
  )
}
