import type { FC } from 'react'
import mixpanel from 'mixpanel-browser'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClientProvider } from 'react-query'
import { Routes } from '@routes/routes'
import { FiltersProvider } from '@hooks/use-filters'
import { ScrollHashProvider } from '@hooks/use-scroll-hash'
import { client } from '@config/react-query'

mixpanel.init('65598926e6ef5fa966695b99ed58d98d') //, { debug: true })
mixpanel.identify()
mixpanel.track('Entrou no Mango')

export const App: FC = () => (
  <HelmetProvider>
    <QueryClientProvider client={client}>
      <ScrollHashProvider>
        <FiltersProvider>
          <Routes />
        </FiltersProvider>
      </ScrollHashProvider>
    </QueryClientProvider>
  </HelmetProvider>
)
