import pipe from 'ramda/src/pipe'
import prop from 'ramda/src/prop'
import sortBy from 'ramda/src/sortBy'
import toLower from 'ramda/src/toLower'
import isNil from 'ramda/src/isNil'

export const sortByProp = <T extends Record<string, unknown>>(
  propName: keyof T,
  xs: T[]
) => sortBy(prop(propName))(xs)

export const sortByPropWithToLower = <T extends Record<string, unknown>>(
  propName: keyof T,
  xs: T[]
) => sortBy(pipe(prop(propName), toLower))(xs)

export const compareByOrder = <T extends { order?: number }>(a: T, b: T) =>
  !isNil(a.order) && !isNil(b.order)
    ? a.order - b.order
    : !isNil(a.order)
      ? -1
      : !isNil(b.order)
        ? 1
        : 0

export const compareBy =
  <T, K extends keyof T>(property: K) =>
    (a: T, b: T): number => {
      const aValue = a[property]
      const bValue = b[property]
      if (!isNil(aValue) && !isNil(bValue)) {
        if (typeof aValue === 'number' && typeof bValue === 'number')
          return aValue - bValue
        if (typeof aValue === 'string' && typeof bValue === 'string')
          return aValue.localeCompare(bValue)
        if (aValue instanceof Date && bValue instanceof Date)
          return aValue.getTime() - bValue.getTime()
      }
      if (!isNil(aValue)) return -1
      if (!isNil(bValue)) return 1
      return 0
    }

export const compareNumbers = (a: number, b: number) =>
  a === b ? 0 : a < b ? -1 : 1
