import mixpanel from 'mixpanel-browser'
import { Checkbox } from '@ui/checkbox'
import { EstablishmentAlert } from '@ui/establishment-alert'
import { Icon } from '@ui/icon'
import { Modal } from '@ui/modal'
import { useFilters } from '@hooks/use-filters'
import { AllergenName, allergenNames } from '@models/allergen'
import { Product } from '@models/product'
import { convertAllergenToIconName } from '@utils/icon/convert-allergen-to-icon-name'
import { locale } from '@utils/locale'
import { isNilOrEmpty } from '@utils/logic/is-nil-or-empty'

type AllergensRestrictionModalProps = {
  products?: Product[]
  visible: boolean
  onClose: () => void
}

export function AllergensRestrictionFilterModal({
  products,
  visible,
  onClose,
}: AllergensRestrictionModalProps) {
  const { allergens: selectedAllergens, toggleAllergen } = useFilters()
  const allergensFromProducts = products
    ?.filter((p: Product) => !isNilOrEmpty(p.allergens) && !!p?.allergensEnable)
    ?.flatMap((p: Product) => p.allergens)
    ?.filter(
      (allergen, index, allergens) => allergens.indexOf(allergen) === index,
    )
    ?.map((allergen) => allergen?.name)

  const getSelectedAllergensAndTrackToMixPanel = (
    checked: boolean,
    allergen: AllergenName,
  ) => {
    let allergensSelected = selectedAllergens
    if (checked) allergensSelected = [...selectedAllergens, allergen]
    else
      allergensSelected = [...selectedAllergens.filter((x) => x !== allergen)]
    mixpanel.track('Escolheu alérgeno para restrição', {
      selectedAllergens: allergensSelected,
    })
  }

  const handleOnAllergenChange = (checked: boolean, allergen: AllergenName) => {
    toggleAllergen(allergen)
    getSelectedAllergensAndTrackToMixPanel(checked, allergen)
  }

  const AllergensContainer = () => {
    return (
      <div className="flex flex-col py-4">
        <h3 className="mx-4 text-left text-lg font-bold leading-6 text-neutral-n2">
          Alergias ou intolerância
        </h3>
        <div className="mt-[22px] flex flex-col gap-1">
          {allergenNames
            .filter((a) => allergensFromProducts?.includes(a))
            .map((allergen) => (
              <div
                key={`div-${allergen}`}
                className="flex h-[52px] justify-between bg-neutral-n12 p-4"
              >
                <div className="flex items-center gap-2 text-base text-neutral-n2">
                  <Icon
                    name={convertAllergenToIconName(allergen)}
                    color="neutral-n4"
                  />
                  {locale.allergen.translate(allergen)}
                </div>

                <Checkbox
                  checked={!!selectedAllergens.includes(allergen)}
                  onChange={(checked) =>
                    handleOnAllergenChange(checked, allergen)
                  }
                />
              </div>
            ))}
        </div>
      </div>
    )
  }

  return (
    <Modal
      title=" "
      className="bg-neutral-n12"
      labelCloseButton="Continuar"
      visible={visible && !!allergensFromProducts?.length}
      onClose={onClose}
    >
      {/* <div className="flex h-[calc(100dvh-72px)] flex-col gap-1"> */}
      <div className="flex flex-col gap-1">
        <div className="flex flex-col items-center justify-center gap-2 px-4 pb-12 text-center">
          <Icon name="soup-off" color="neutral-n6" size={64} />
          <span className="text-2xl font-bold text-neutral-n2">
            Você possui alguma restrição?
          </span>
          <span className="text-base font-medium text-neutral-n6">
            Os itens que contenham os alergênicos que você selecionar serão
            ocultados do cardápio.
          </span>
        </div>
        <div className="flex flex-1 flex-col justify-between gap-4 bg-neutral-n11">
          <AllergensContainer />
          <EstablishmentAlert className="pb-4" />
        </div>
      </div>
    </Modal>
  )
}
