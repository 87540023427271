import { useState } from 'react'
import mixpanel from 'mixpanel-browser'
import { Currency } from '@ui/currency'
import { EstablishmentAlert } from '@ui/establishment-alert'
import { Img } from '@ui/img'
import { Modal } from '@ui/modal'
import { Option } from '@models/complement'
import { ContainTraces } from './product-details-modal'
import { ProductImageModal } from './product-image-modal'

type OptionDetailsModalProps = {
  option?: Option
  visible: boolean
  onClose: () => void
}

export function OptionDetailsModal({
  option,
  visible = false,
  onClose = () => {},
}: OptionDetailsModalProps) {
  const [imgModalOpen, setImgModalOpen] = useState(false)
  const handleOnOptionImageClick = () => {
    setImgModalOpen(() => true)
    mixpanel.track('Clicou para expandir imagem da Opção', { option })
  }

  return (
    <Modal closeButtonHidden title="Opção" visible={visible} onClose={onClose}>
      {!!option && (
        <>
          <div className="flex flex-col">
            {!!option.imageUrl && (
              <Img
                className="w-full"
                // className="h-[201px] w-full object-cover"
                src={option.imageUrl}
                isThumbnail={false}
                width={390}
                height={201}
                autoGravity
                quality="auto:best"
                alt="imagem da opção"
                onClick={handleOnOptionImageClick}
              />
            )}

            {!option.available && (
              <div className="flex h-8 items-center justify-center bg-neutral-n10 text-sm font-bold text-neutral-n4">
                Indisponível
              </div>
            )}

            <div className="bg-neutral-n12 p-4">
              <div className="flex gap-1">
                {!!option.pdvCode && (
                  <h1 className="text-xl font-bold leading-6 text-neutral-n6">
                    {option.pdvCode}
                  </h1>
                )}
                <h1 className="text-xl font-bold leading-6 text-neutral-n2">
                  {option.name}
                </h1>
              </div>

              <p className="mt-2 font-medium leading-6 text-neutral-n6">
                {option.description}
              </p>

              {!!option.available && (
                <div className="mt-4 py-2">
                  <div className="mt-2 flex flex-col gap-1">
                    <div className="flex items-center gap-2">
                      <Currency className="text-base font-medium text-neutral-n4">
                        {option.priceTable || 0}
                      </Currency>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {!!option.allergens?.length && !!option.allergensEnable && (
              <div className="flex flex-row gap-2 p-4">
                {!!option.allergens.filter((a) => !a.mayContainTraces)
                  .length && (
                  <ContainTraces
                    allergens={option.allergens.filter(
                      (a) => !a.mayContainTraces
                    )}
                    title="Contém"
                  />
                )}
                {!!option.allergens.filter((a) => a.mayContainTraces)
                  .length && (
                  <ContainTraces
                    allergens={option.allergens.filter(
                      (a) => a.mayContainTraces
                    )}
                    title="Pode conter traços"
                  />
                )}
              </div>
            )}

            <div className="flex-1" />

            <EstablishmentAlert className="mx-4 mt-[22px] pb-4" />
          </div>

          {!!option.imageUrl && (
            <ProductImageModal
              visible={imgModalOpen}
              onClose={() => setImgModalOpen(() => false)}
              imageUrl={option.imageUrl}
            />
          )}
        </>
      )}
    </Modal>
  )
}
