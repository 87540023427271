import { type ComponentPropsWithoutRef } from 'react'
import { cn } from '@utils/styles'

type SpanProps = ComponentPropsWithoutRef<'span'>

export function EstablishmentAlert({ ...otherProps }: SpanProps) {
  return (
    <span
      {...otherProps}
      className={cn(
        'min-h-[32px] text-center text-sm font-medium leading-4 text-neutral-n6',
        otherProps.className
      )}
    >
      Todas as informações contidas no cardápio são de inteira responsabilidade
      do estabelecimento
    </span>
  )
}
