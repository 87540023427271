import type { HTMLProps } from 'react'
import { toCurrency } from '@utils/math/toCurrency'

type CurrencyProps = HTMLProps<HTMLParagraphElement> & {
  children: number
}

export function Currency({ children, ...otherProps }: CurrencyProps) {
  const formatted = toCurrency(children)
  return <p {...otherProps}>{formatted}</p>
}
