import { AllData } from '@models/all-data'
import { Category } from '@models/category'
import { Product } from '@models/product'
import { Store } from '@models/store'
import { env } from '@config/env'
import { Complement } from '@models/complement'

export function fetchWithApiUrl(url: string, options?: RequestInit) {
  // const searchParams = new URLSearchParams(window.location.search);
  // const keys = searchParams.keys(); // entries.toArray()
  // const [unitCode] = Array.from(keys)
  const unitCode = new URLSearchParams(window.location.search).get('u')
  const domain = new URLSearchParams(window.location.search).get('d')
  let fullUrl = `${env.apiUrl}${url}${!!unitCode ? (url.includes('?') ? '&' : '?') + 'u=' + unitCode : ''}`
  fullUrl = `${fullUrl}${!!domain ? (fullUrl.includes('?') ? '&' : '?') + 'd=' + domain : ''}`
  return fetch(fullUrl, options)
}

async function getStore(): Promise<Store> {
  const response = await fetchWithApiUrl(`/stores`)
  const data = await response.json()
  return data
}

async function getCategories(): Promise<Category[]> {
  const response = await fetchWithApiUrl('/categories')
  const data = await response.json()
  return data
}

async function getProducts(): Promise<Product[]> {
  const response = await fetchWithApiUrl('/products')
  const data = await response.json()
  return data
}

async function getProduct(id: string): Promise<Product> {
  const response = await fetchWithApiUrl(`/products/${id}`)
  const data = await response.json()
  return data
}

async function getComplements(): Promise<Complement[]> {
  const response = await fetchWithApiUrl('/complements')
  const data = await response.json()
  return data
}

async function getAllData(): Promise<AllData> {
  const result = await Promise.all([getStore(), getProducts(), getComplements()])

  return {
    store: result?.[0],
    products: result?.[1],
    complements: result?.[2],
  }
}

export const api = {
  getStore,
  getCategories,
  getProducts,
  getProduct,
  getComplements,
  getAllData,
}
