import { type ComponentPropsWithoutRef } from 'react'
import { cn } from '@utils/styles'

type SpanProps = ComponentPropsWithoutRef<'span'> & { text: string }

export function TextBalloon({ text, ...otherProps }: SpanProps) {
  return (
    <div className="mb-[2px] content-center">
      <span
        className={cn(
          'rounded bg-neutral-n4 px-2 py-1 text-xs font-medium text-neutral-n12',
          otherProps.className
        )}
      >
        {text}
      </span>
    </div>
  )
}
