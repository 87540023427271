import { useState } from 'react'
import { cva } from 'cva'
import mixpanel from 'mixpanel-browser'
import { useInterval, useToggle } from 'usehooks-ts'
import { Header } from '@ui/header'
import { Icon } from '@ui/icon'
import { IconButton } from '@ui/icon/icon-button'
import { Img } from '@ui/img'
import { Store } from '@models/store'
import { BusinessWeekDay, dayOfWeek, nextDayOfWeek } from '@models/week-days'
import { locale } from '@utils/locale'
import { modal, useVisibleModals } from './filters/use-visible-modals'
import { QrCodeModal } from './qr-code-modal'
import { StoreDetailsModal } from './store-details-modal'

const contentVisibleIconVariants = cva(['transition-transform'], {
  variants: {
    visible: {
      true: 'rotate-180',
    },
  },
  defaultVariants: {
    visible: false,
  },
})

type StoreInfoProps = {
  store?: Store
}

export function StoreInfo({ store }: StoreInfoProps) {
  const [contentCollapsed, toggleContentCollapsed] = useToggle(false)
  const [delay, setDelay] = useState<number>(1000)
  const [storeBusinessOpenMessage, setStoreBusinessOpenMessage] =
    useState<string>('')
  const [storeBusinessTimerMessage, setStoreBusinessTimerMessage] =
    useState<string>('')

  useInterval(() => {
    const currentDate = new Date()
    const convertToMinutes = (time: string) => {
      const [hours, minutes] = time.split(':')
      return parseInt(hours, 10) * 60 + parseInt(minutes, 10)
    }

    const businessWeekDaysAvailable =
      unit?.businessWeekDays?.filter((x) => x.available) ||
      store?.businessWeekDays?.filter((x) => x.available) ||
      []

    const currentWeekDay = (
      unit?.businessWeekDays || store?.businessWeekDays
    )?.find((x) => x.available && x.name === dayOfWeek)

    const getOpenedIntervalByCurrentTimer = (
      businessWeekDay: BusinessWeekDay,
    ) => {
      const timerStrig = currentDate.toLocaleTimeString('pt-BR')
      return businessWeekDay?.intervals?.find((interval) => {
        const { startHour, endHour } = interval
        const isAfterStartHour =
          convertToMinutes(startHour) < convertToMinutes(timerStrig)
        const isBeforeEndHour =
          convertToMinutes(endHour) > convertToMinutes(timerStrig)
        const isOpen = isAfterStartHour && isBeforeEndHour
        if (isOpen) {
          return interval
        }
        return null
      })
    }

    const getCLosedIntervalByCurrentTimer = (
      businessWeekDay: BusinessWeekDay,
    ) => {
      const timerStrig = currentDate.toLocaleTimeString('pt-BR')
      return businessWeekDay?.intervals?.find((interval) => {
        const { endHour } = interval
        const isBeforeEndHour =
          convertToMinutes(endHour) > convertToMinutes(timerStrig)
        const isClosedYet = isBeforeEndHour
        if (isClosedYet) {
          return interval
        }
        return null
      })
    }

    let messageOpen = ''
    let messageTimer = ''
    if (currentWeekDay && !!currentWeekDay.intervals?.length) {
      const todayInterval = getOpenedIntervalByCurrentTimer(currentWeekDay)
      if (!!todayInterval) {
        messageOpen = `Aberto`
        messageTimer = `Fecha às ${todayInterval.endHour}`
        const timerStrig = currentDate.toLocaleTimeString('pt-BR')
        const currentMinutes = convertToMinutes(timerStrig)
        const endHoursMinutes = convertToMinutes(todayInterval.endHour)
        const minutesToClose = endHoursMinutes - currentMinutes
        if (minutesToClose < 60) {
          messageTimer = `Fecha em ${minutesToClose} minutos`
          if (minutesToClose === 1)
            messageTimer = `Fecha em ${minutesToClose} minuto`
          if (minutesToClose <= 0) {
            messageTimer = ''
            messageOpen = ''
          }
        }
        const tomorrow = businessWeekDaysAvailable.find(
          (x) => x.name === nextDayOfWeek,
        )
        if (
          !!tomorrow &&
          !!tomorrow.intervals[0] &&
          todayInterval.endHour === '23:59'
        ) {
          const tomorrowInterval = tomorrow.intervals[0]
          if (!!tomorrowInterval && tomorrowInterval.startHour === '00:00') {
            messageTimer = `Fecha Amanhã às ${tomorrowInterval.endHour}`
          }
        }
      }
    }
    if (!messageOpen) {
      const firstWeekDayIndex = 0
      const lastWeekDayIndex = 6
      let weekDayIndex = currentDate.getDay()
      let count = 0
      while (count < lastWeekDayIndex) {
        if (weekDayIndex > lastWeekDayIndex) {
          weekDayIndex = firstWeekDayIndex
        }
        const weekDay = (unit?.businessWeekDays || store?.businessWeekDays)?.[
          weekDayIndex
        ]
        const hasOnlyOneBusinessWeekDaysAvailable =
          businessWeekDaysAvailable.length === 1
        if (!!weekDay && weekDay.available && !!weekDay.intervals?.length) {
          const sameWeekDay = weekDay.name === dayOfWeek
          let day = sameWeekDay
            ? 'Hoje'
            : locale.weekDay.translate(weekDay.name)
          const interval = getCLosedIntervalByCurrentTimer(weekDay)
          let hour = ''

          if (sameWeekDay && interval) {
            hour = interval.startHour
          }
          if (
            (sameWeekDay && !interval && hasOnlyOneBusinessWeekDaysAvailable) ||
            !sameWeekDay
          ) {
            hour = weekDay.intervals[0].startHour
            if (sameWeekDay && hasOnlyOneBusinessWeekDaysAvailable) {
              day = `próximo ${locale.weekDay.translate(weekDay.name)}`
            }
          }

          if (!messageOpen && hour) {
            messageOpen = `Fechado`
            messageTimer = `Abre ${day} às ${hour}`
          }
        }
        count = count + 1
        weekDayIndex = weekDayIndex + 1
      }
    }
    setStoreBusinessOpenMessage(messageOpen)
    setStoreBusinessTimerMessage(messageTimer)
    if (delay <= 1000) setDelay(30 * 1000)
  }, delay)

  const { includes, toggle } = useVisibleModals()
  // TODO improve with loading state
  if (!store) {
    return null
  }

  const { fullName, unit, imageUrl } = store

  mixpanel.people.set({ store })

  const handleOnQrCodeModalClick = () => {
    toggle(modal.qrcode)
    mixpanel.track('Clicou em qr code')
  }

  const handleOnQrCodeModalClose = () => {
    toggle(modal.qrcode)
    mixpanel.track('Fechou qr code')
  }

  const handleOnStoreDatailsClick = () => {
    toggle(modal.storedetails)
    toggleContentCollapsed()
    mixpanel.track('Clicou em detalhes do restaurante')
  }

  const handleOnStoreDatailsModalClose = () => {
    toggle(modal.storedetails)
    toggleContentCollapsed()
    mixpanel.track('Fechou detalhes do restaurante')
  }

  return (
    <>
      <Header className="z-30 flex items-center gap-4 px-4">
        <figure className="overflow-hidden rounded-full">
          <Img
            src={imageUrl}
            alt="logo do estabelecimento"
            width={64}
            height={64}
          />
        </figure>

        <div className="flex flex-1 flex-col">
          <span className="font-medium text-neutral-n4">{fullName}</span>
          {/* {unit?.name && (
            <span className="text-sm font-medium text-neutral-n6">
              {unit?.name}
            </span>
          )} */}
          <div className="flex items-center gap-2">
            <span className="text-xs font-bold text-neutral-n6">
              {storeBusinessOpenMessage}
            </span>
            {storeBusinessTimerMessage && (
              <>
                <div className="h-1 w-1 rounded-full bg-neutral-n10"></div>
                <span className="text-xs font-medium text-neutral-n6">
                  {storeBusinessTimerMessage}
                </span>
              </>
            )}
          </div>
        </div>

        <Icon
          className="hidden"
          name="qr-code"
          color="neutral-n4"
          onClick={handleOnQrCodeModalClick}
        />

        {(!!store?.unit?.businessWeekDays?.some((x) => x.available) ||
          !!store?.businessWeekDays?.some((x) => x.available) ||
          !!store.about ||
          !!store?.unit?.cep ||
          !!store?.cep ||
          !!store?.unit?.contacts?.some((x) => !!x.phone) ||
          !!store?.contacts?.some((x) => !!x.phone)) && (
          <IconButton
            className={contentVisibleIconVariants({
              visible: contentCollapsed,
            })}
            intent="tertiary"
            iconName="chevron-down"
            type="button"
            onClick={handleOnStoreDatailsClick}
          />
        )}
      </Header>

      <QrCodeModal
        visible={includes(modal.qrcode)}
        onClose={handleOnQrCodeModalClose}
      />

      <StoreDetailsModal
        store={store}
        visible={includes(modal.storedetails)}
        onClose={handleOnStoreDatailsModalClose}
      />
    </>
  )
}
