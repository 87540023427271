import { useContext, useEffect, useState } from 'react'
import { useFunctions } from '@hooks/use-functions'
import type { Hash } from './common-types'
import { ScrollHashContext } from './context'

export const useScrollHash = () => {
  const { getFunction } = useFunctions()
  const { activeHash } = useContext(ScrollHashContext)

  const scrollTo = (hash: Hash) => {
    const fn = getFunction(hash)
    fn()
  }

  return { activeHash, scrollTo }
}

export const useWindowScrollPositions = () => {
  const [scrollPosition, setPosition] = useState({ scrollX: 0, scrollY: 0 })

  useEffect(() => {
    function updatePosition() {
      setPosition({ scrollX: window.scrollX, scrollY: window.scrollY })
    }

    window.addEventListener('scroll', updatePosition)
    updatePosition()

    return () => window.removeEventListener('scroll', updatePosition)
  }, [])

  return scrollPosition
}
