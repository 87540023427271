import type { ReactNode } from 'react'
import { cva, cx } from 'cva'

const strokeVariants = cva('', {
  variants: {
    checked: {
      true: 'stroke-primary-p-d20 fill-primary-p-d20',
      false: 'stroke-neutral-n8',
    },
    halfChecked: {
      true: 'stroke-primary-p-d20 fill-primary-p-d20',
      false: 'stroke-neutral-n8',
    },
  },
  defaultVariants: {
    checked: false,
    halfChecked: false,
  },
})

type CheckboxProps = {
  checked: boolean
  halfChecked?: boolean
  className?: string
  label?: ReactNode
  onChange: (value: boolean) => void
}

// TODO create a 'inputs' folder. Move this to it
// TODO rename it to InputCheckbox
export function Checkbox({
  checked,
  halfChecked,
  className,
  label = '',
  onChange,
}: CheckboxProps) {
  const handleClick = () => {
    onChange(!checked)
  }

  const classNames = cx('flex items-center gap-5', className)

  return (
    <div className={classNames} onClick={handleClick}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          className={strokeVariants({ checked, halfChecked })}
          x="3"
          y="3"
          width="18"
          height="18"
          rx="3"
          strokeWidth="2"
        />

        {!checked && !!halfChecked && (
          <path
            d="M6 12H18"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        )}

        {!!checked && !halfChecked && (
          <path
            d="M7 12L10 15L17 9"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}
      </svg>

      {!!label && (
        <label className="text-sm font-bold text-neutral-n4">{label}</label>
      )}
    </div>
  )
}
