import type { Hash } from './common-types'

type ScrollToParams = {
  element: HTMLElement
  referenceElement: HTMLElement
  scrollableElement: HTMLElement | Window
  offset?: number
}

export const scrollTo = ({
  element,
  referenceElement,
  scrollableElement,
  offset = 0,
}: ScrollToParams) => {
  const referenceElementRect = referenceElement.getBoundingClientRect()
  const elementRect = element.getBoundingClientRect()
  const elementPosition = elementRect.top - referenceElementRect.top
  const offsetPosition = elementPosition - offset

  scrollableElement.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  })
}

type GetActiveHashParams = {
  elements: [Hash, HTMLElement][]
  referenceElement: HTMLElement
  offset: number
}

export const getActiveHash = ({
  elements,
  referenceElement,
  offset,
}: GetActiveHashParams) =>
  elements.reduce((activeHash, [hash, element]) => {
    const referenceElementRect = referenceElement.getBoundingClientRect()

    if (
      element.offsetTop !== 0 &&
      Math.abs(referenceElementRect.y) >= element.offsetTop - offset
    ) {
      return hash
    }

    return activeHash
  }, '')
