import { createContext, useCallback, useReducer } from 'react'
import type { ReactNode, Reducer } from 'react'
import without from 'ramda/src/without'
import type { AllergenName } from '@models/allergen'
import { Option } from '@models/complement'
import type { Product } from '@models/product'

type State = {
  // FIXME should it be Allergen instead of AllergenName?
  allergenNames: AllergenName[]
  stamps: FilterStamps
  searchTerm: string
  selectedProduct?: Product
  selectedOption?: Option
}

export type FilterStamps = {
  promotionSelected: boolean
  moreOrdersSelected: boolean
  expressSelected: boolean
  withoutStampSelected: boolean
  allStampSelected: boolean
}

type ToggleAllergen = {
  type: 'toggle-allergen'
  payload: AllergenName
}

type ToggleStamps = {
  type: 'toggle-stamps'
  payload: FilterStamps
}

type SetSearchTerm = {
  type: 'set-search-term'
  payload: string
}

type SelectProduct = {
  type: 'select-product'
  payload?: Product
}

type SelectOption = {
  type: 'select-option'
  payload?: Option
}

type Action =
  | ToggleAllergen
  | ToggleStamps
  | SetSearchTerm
  | SelectProduct
  | SelectOption

const initialState: State = {
  allergenNames: [],
  stamps: {
    promotionSelected: true,
    moreOrdersSelected: true,
    expressSelected: true,
    withoutStampSelected: true,
    allStampSelected: true,
  } as FilterStamps,
  searchTerm: '',
  selectedProduct: undefined,
  selectedOption: undefined,
}

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'set-search-term': {
      return {
        ...state,
        searchTerm: action.payload,
      }
    }
    case 'toggle-allergen': {
      const allergenName = action.payload
      const allergenNames = state.allergenNames

      const stateContainsAllergen = allergenNames.some(
        (a) => a === allergenName
      )

      if (stateContainsAllergen) {
        return {
          ...state,
          allergenNames: without([allergenName], allergenNames),
        }
      } else {
        return {
          ...state,
          allergenNames: [...allergenNames, allergenName],
        }
      }
    }
    case 'toggle-stamps': {
      const stamps = action.payload
      return {
        ...state,
        stamps: stamps,
      }
    }
    case 'select-product': {
      const product = action.payload

      return {
        ...state,
        selectedProduct: product,
      }
    }
    case 'select-option': {
      const option = action.payload

      return {
        ...state,
        selectedOption: option,
      }
    }
    default:
      throw Error(`Unexpected action ${action}`)
  }
}

type FiltersContext = {
  allergens: AllergenName[]
  stamps: FilterStamps
  searchTerm: string
  selectedProduct?: Product
  selectedOption?: Option
  toggleAllergen: (allergenName: AllergenName) => void
  toggleStamps: (stamps: FilterStamps) => void
  setSearchTerm: (term: string) => void
  selectProduct: (product?: Product) => void
  selectOption: (option?: Option) => void
}

export const filtersContext = createContext<FiltersContext>({
  allergens: [],
  stamps: {
    promotionSelected: true,
    moreOrdersSelected: true,
    expressSelected: true,
    withoutStampSelected: true,
    allStampSelected: true,
  } as FilterStamps,
  searchTerm: '',
  selectedProduct: undefined,
  selectedOption: undefined,
  toggleAllergen: () => {},
  toggleStamps: () => {},
  setSearchTerm: () => {},
  selectProduct: () => {},
  selectOption: () => {},
})

const FiltersContextProvider = filtersContext.Provider

type FiltersProvidersProps = {
  children: ReactNode
}

export function FiltersProvider({ children }: FiltersProvidersProps) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const toggleAllergen = useCallback(
    (allergenName: AllergenName) =>
      dispatch({ type: 'toggle-allergen', payload: allergenName }),
    [dispatch]
  )

  const toggleStamps = useCallback(
    (stamps: FilterStamps) =>
      dispatch({ type: 'toggle-stamps', payload: stamps }),
    [dispatch]
  )

  const setSearchTerm = useCallback(
    (term: string) => dispatch({ type: 'set-search-term', payload: term }),
    [dispatch]
  )

  const selectProduct = useCallback(
    (product?: Product) =>
      dispatch({ type: 'select-product', payload: product }),
    [dispatch]
  )

  const selectOption = useCallback(
    (option?: Option) => dispatch({ type: 'select-option', payload: option }),
    [dispatch]
  )

  const value = {
    allergens: state.allergenNames,
    stamps: state.stamps,
    searchTerm: state.searchTerm,
    selectedProduct: state.selectedProduct,
    selectedOption: state.selectedOption,
    toggleAllergen,
    toggleStamps,
    setSearchTerm,
    selectProduct,
    selectOption,
  }

  return (
    <FiltersContextProvider value={value}>{children}</FiltersContextProvider>
  )
}
