import { useEffect, useState } from 'react'
import type { ComponentPropsWithoutRef } from 'react'
import { AdvancedImage, lazyload } from '@cloudinary/react'
import { thumbnail, fill } from '@cloudinary/url-gen/actions/resize'
import { CloudinaryImage } from '@cloudinary/url-gen/assets/CloudinaryImage'
import { Cloudinary } from '@cloudinary/url-gen/instance/Cloudinary'
import { autoGravity as cloudinaryAutoGravity } from '@cloudinary/url-gen/qualifiers/gravity'
import { extractCloudName, extractPublicId } from './img-utils'

type ImageProps = ComponentPropsWithoutRef<'img'> & {
  width?: number
  height?: number
  lazy?: boolean
  autoGravity?: boolean
  doResize?: boolean
  isThumbnail?: boolean
  quality?: string
  alt: string
}

export function Img({
  src = '',
  width = 0,
  height = 0,
  lazy = false,
  autoGravity = false,
  doResize = true,
  isThumbnail = true,
  quality = '',
  ...otherProps
}: ImageProps) {
  const [image, setImage] = useState<CloudinaryImage>()

  useEffect(() => {
    if (!src || !src?.includes('cloudinary')) {
      return
    }

    const cld = new Cloudinary({
      cloud: {
        cloudName: extractCloudName(src),
      },
    })

    const image = cld.image(extractPublicId(src))

    if (doResize) {
      let resize = isThumbnail ? thumbnail() : fill()

      if (!!autoGravity) {
        resize.gravity(cloudinaryAutoGravity())
      }

      if (!!width) {
        resize.width(width)
      }

      if (!!height) {
        resize.height(height)
      }

      image.resize(resize).format('webp')
    }

    if (!!quality) {
      image.quality(quality)
    }

    setImage(image)
  }, [src, width, height, autoGravity, doResize, isThumbnail, quality])

  const plugins = !!lazy ? [lazyload()] : undefined

  return !image ? (
    <span>...</span>
  ) : (
    <AdvancedImage cldImg={image} plugins={plugins} {...otherProps} />
  )
}
