import { isNil } from 'ramda'
import { ProductSection } from '@routes/menu/support-components/product-list/product-section'
import { Complement } from '@models/complement'
import type { Product } from '@models/product'
import { modal } from '../menu/support-components/filters/use-visible-modals'

type StampsViewProps = {
  products?: Product[]
  complements?: Complement[]
  stopProductClick?: boolean
  modalType?: string
}

export function StampsView({
  products,
  complements,
  modalType,
  stopProductClick = true,
}: StampsViewProps) {
  const productsPromotion = products?.filter(
    (x) => !!x.stamps?.promotion?.enable, // && !!x.available,
  )
  const productsMoreOrders = products
    ?.filter(
      (x) => !!x.stamps?.moreOrders?.enable, // && !!x.available
    )
    .sort((a, b) => {
      return !isNil(a.stamps?.moreOrders?.order) &&
        !isNil(b.stamps?.moreOrders?.order)
        ? a.stamps?.moreOrders?.order! - b.stamps?.moreOrders?.order!
        : !isNil(a.stamps?.moreOrders?.order)
          ? -1
          : !isNil(b.stamps?.moreOrders?.order)
            ? 1
            : 0
    })
  return (
    <div className="flex flex-col">
      <h1 className="flex justify-center bg-neutral-n12 px-8 py-3 text-center text-base font-medium text-neutral-n4">
        Confira algumas das melhores opções que separamos para você!
      </h1>
      {modalType === modal.promotionStamp && !!productsPromotion?.length && (
        <ProductSection
          iconName="discount2"
          stopProductClick={stopProductClick}
          products={productsPromotion}
          allProducts={products}
          complements={complements}
          isFromSuggestion
        />
      )}
      {modalType === modal.moreOrdersStamp && !!productsMoreOrders?.length && (
        <ProductSection
          iconName="star"
          stopProductClick={stopProductClick}
          dontDoAutoSort
          products={productsMoreOrders}
          allProducts={products}
          complements={complements}
          isFromSuggestion
        />
      )}
    </div>
  )
}
