import { cx } from 'cva'
import { Icon, IconProps } from './icon'

type SpinnerProps = Omit<IconProps, 'name'>

export function Spinner({ className, size = 42, ...otherProps }: SpinnerProps) {
  return (
    <Icon
      className={cx('animate-spin', className)}
      name="spinner"
      color="neutral-n2"
      size={size}
      {...otherProps}
    />
  )
}
