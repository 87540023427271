import { Icon, IconProps, IconName } from '@ui/icon'

type HeaderIconButtonProps = Omit<IconProps, 'name' | 'onClick'> & {
  iconName: IconName
  onClick?: () => void
}

export function HeaderIconButton({
  iconName,
  onClick = () => {},
  ...otherProps
}: HeaderIconButtonProps) {
  return (
    <div
      className="flex h-[72px] w-[72px] items-center justify-center"
      onClick={onClick}
    >
      <Icon name={iconName} size={32} {...otherProps} />
    </div>
  )
}
