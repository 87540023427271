import type { CSSProperties, ReactNode } from 'react'
import { cva } from 'cva'
import type { VariantProps } from 'cva'
import { createPortal } from 'react-dom'
import { Header, HeaderIconButton } from '@ui/header'
import { cn } from '@utils/styles'
import { IconName } from './icon'

const modalVariants = cva(
  [
    'fixed',
    // 'top-0',
    //'h-screen',
    'max-w-3xl m-auto inset-x-0', // shadow-xl max-md:shadow-none', //'w-screen',
    'flex',
    'flex-col',
    'bg-neutral-n11',
    'overflow-y-auto',
    'transition-transform',
    'duration-500',
  ],
  {
    variants: {
      visible: {
        true: 'trackable-purpose-modal-open-class',
        false: 'translate-y-[-100%]',
        // false: 'translate-y-[115%]',
      },
    },
    defaultVariants: {
      visible: false,
    },
  },
)

type ModalProps = VariantProps<typeof modalVariants> & {
  children: ReactNode
  className?: string
  style?: CSSProperties
  closeButtonHidden?: boolean
  labelCloseButton?: string
  iconName?: IconName
  title?: string
  hideHeader?: boolean
  onClose?: () => void
}

// TODO create a translucide overlay behind this modal.
// It should animate opacity from 0 to 1
export function Modal({
  children,
  className = '',
  style = undefined,
  closeButtonHidden = false,
  labelCloseButton = 'Fechar',
  iconName = 'close',
  title = '',
  visible = false,
  hideHeader = false,
  onClose = () => {},
}: ModalProps) {
  return createPortal(
    <div
      style={style}
      className={cn(
        'top-0 z-50 h-screen',
        modalVariants({ visible }),
        className,
      )}
    >
      <div className="flex flex-1 flex-col overflow-y-auto">
        {!hideHeader && (
          <Header
            title={title}
            className="z-1"
            rightElement={
              <HeaderIconButton
                iconName={iconName}
                color="neutral-n6"
                onClick={onClose}
              />
            }
            sticky
          />
        )}

        {children}
      </div>

      {!closeButtonHidden && (
        <button
          type="button"
          className="h-[56px] bg-neutral-n12 font-bold text-primary-p-0"
          onClick={onClose}
        >
          {labelCloseButton}
        </button>
      )}
    </div>,
    document.body,
  )
}
