import {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from 'react'
import type { MutableRefObject } from 'react'
import type { Dispatch, ReactNode, Reducer } from 'react'
import mixpanel from 'mixpanel-browser'
import lensPath from 'ramda/src/lensPath'
import pipe from 'ramda/src/pipe'
import reduce from 'ramda/src/reduce'
import sortBy from 'ramda/src/sortBy'
import view from 'ramda/src/view'
import { FunctionsProvider } from '@hooks/use-functions'
import type { Hash } from './common-types'
import { getActiveHash } from './utils'

type HashValue = { element: HTMLElement; order?: number }
type Elements = Map<Hash, HashValue>

type State = {
  activeHash?: string
  elements: Elements
  offset: number
}

const initialState: State = {
  activeHash: '',
  elements: new Map<Hash, HashValue>(),
  offset: 0,
}

type SetActiveHash = {
  type: 'set-active-hash'
  payload: Hash
}

type AddElement = {
  type: 'add-element'
  payload: {
    hash: Hash
    element: HTMLElement
    order: number
  }
}

type RemoveElement = {
  type: 'remove-element'
  payload: Hash
}

type SetOffset = {
  type: 'set-offset'
  payload: number
}

type Action = SetActiveHash | AddElement | RemoveElement | SetOffset

type Tuple = [Hash, HashValue]

export const getOrderProp = (tuple: Tuple) =>
  view(lensPath([1, 'order']))(tuple)

const buildElementsMap = (hash: Hash, hashValue: HashValue, xs: Elements) => {
  xs.set(hash, hashValue)

  return pipe(
    (elementsMap) => [...elementsMap],
    sortBy(getOrderProp),
    reduce((map, [hash, hashValue]) => map.set(hash, hashValue), new Map())
  )(xs)
}

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'set-active-hash': {
      return {
        ...state,
        activeHash: action.payload,
      }
    }
    case 'add-element': {
      const { hash, element, order } = action.payload

      const hashValue = { element, order }
      const elements = buildElementsMap(hash, hashValue, state.elements)

      return {
        ...state,
        elements,
      }
    }
    case 'remove-element': {
      const hash = action.payload
      state.elements.delete(hash)
      return {
        ...state,
        elements: new Map(state.elements),
      }
    }
    case 'set-offset': {
      return {
        ...state,
        offset: action.payload,
      }
    }
    default:
      throw Error(`Unknown action '${action}'`)
  }
}

type UseScrollHashContext = {
  activeHash?: Hash
  elements: Elements
  addElement: (hash: Hash, element: HTMLElement, order: number) => void
  removeElement: (hash: Hash) => void
  setOffset: (offset: number) => void
}

export const ScrollHashContext = createContext<UseScrollHashContext>({
  activeHash: '',
  elements: new Map(),
  addElement: () => {},
  removeElement: () => {},
  setOffset: () => {},
})

type ScrollHashProviderProps = {
  children: ReactNode
}

type ScrollListenerInput = {
  elements: Elements
  offset: number
  previousHashRef: MutableRefObject<string>
  dispatch: Dispatch<Action>
}

const initScrollListener = ({
  elements,
  offset,
  previousHashRef,
  dispatch,
}: ScrollListenerInput) => {
  const mappedElements: [Hash, HTMLElement][] = Array.from(
    elements,
    ([hash, hashValue]) => [hash, hashValue.element]
  )

  return () => {
    const activeHash = getActiveHash({
      elements: mappedElements,
      referenceElement: document.body,
      offset,
    })

    const hashChanged = activeHash !== previousHashRef.current

    if (hashChanged) {
      dispatch({ type: 'set-active-hash', payload: activeHash })
      previousHashRef.current = activeHash
      mixpanel.track('Moveu o scroll para outra categoria', {
        category: activeHash,
      })
    }
  }
}

export function ScrollHashProvider({ children }: ScrollHashProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const previousHashRef = useRef('')

  useEffect(() => {
    const listener = initScrollListener({
      elements: state.elements,
      offset: state.offset,
      previousHashRef,
      dispatch,
    })

    window.addEventListener('scroll', listener)

    return () => window.removeEventListener('scroll', listener)
  }, [state.elements, state.offset])

  const addElement = useCallback(
    (hash: Hash, element: HTMLElement, order: number) => {
      dispatch({ type: 'add-element', payload: { hash, element, order } })
    },
    [dispatch]
  )

  const removeElement = useCallback(
    (hash: Hash) => {
      dispatch({ type: 'remove-element', payload: hash })
    },
    [dispatch]
  )

  const setOffset = useCallback(
    (offset: number) => {
      dispatch({ type: 'set-offset', payload: offset })
    },
    [dispatch]
  )

  const value = {
    activeHash: state.activeHash,
    elements: state.elements,
    addElement,
    removeElement,
    setOffset,
  }

  return (
    <ScrollHashContext.Provider value={value}>
      <FunctionsProvider>{children}</FunctionsProvider>
    </ScrollHashContext.Provider>
  )
}
