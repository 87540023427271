import { StampsView } from '@/components/routes/suggestions/stamps-view'
import { Complement } from '@/models/complement'
import { Modal } from '@ui/modal'
import type { Product } from '@models/product'
import { modal } from './use-visible-modals'

type StampsModalProps = {
  products?: Product[]
  complements?: Complement[]
  visible: boolean
  modalType?: string
  onClose?: () => void
}

export function StampsModal({
  products,
  complements,
  visible,
  modalType,
  onClose,
}: StampsModalProps) {
  return (
    <Modal
      className="z-40"
      title={
        !visible
          ? ' '
          : modalType === modal.moreOrdersStamp
            ? 'Os Mais Vendidos'
            : 'Promoções'
      }
      labelCloseButton="Ver cardápio completo"
      visible={visible}
      onClose={onClose}
    >
      <StampsView
        modalType={!visible ? '' : modalType}
        products={products}
        complements={complements}
        stopProductClick={false}
      />
    </Modal>
  )
}
