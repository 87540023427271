import { useEffect, useState } from 'react'
import mixpanel from 'mixpanel-browser'
import { QRCodeSVG } from 'qrcode.react'
import { useQuery } from 'react-query'
import { Button } from '@ui/icon/button'
import { Modal } from '@ui/modal'
import { Spinner } from '@ui/spinner'
import { queryIds } from '@config/react-query'
import { api } from '@api'

type QrCodeModalProps = {
  visible: boolean
  onClose: () => void
}

export function QrCodeModal({ visible, onClose }: QrCodeModalProps) {
  const [hidePin, setHidePin] = useState(true)
  const [copyPix, setCopyPix] = useState(false)
  const [pin, setPin] = useState('')
  const [pixQrCodeValue, setPixQrCodeValue] = useState('')
  const { isLoading, data } = useQuery(queryIds.store, api.getStore, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  })

  useEffect(() => {
    if (!!data?.domain && !!pin) {
      const topic = `mango-${data?.domain}-${pin}`
      // console.log(topic)
      const hashTopicNtfy = window.btoa(topic).replaceAll('=', '_')
      const uri = `https://ntfy.sh/${hashTopicNtfy}/sse`
      // console.log(uri)
      const eventSource = new EventSource(uri)
      eventSource.onmessage = (e) => {
        const message = JSON.parse(e.data).message
        const pixData = window.atob(message === 'triggered' ? '' : message)
        // console.log(pixData)
        if (!!pixData) {
          setHidePin(false)
          setPixQrCodeValue(pixData)
        } else {
          setHidePin(true)
          setPixQrCodeValue('')
          setPin('')
        }
      }
    }
  }, [data?.domain, pin])

  const handleOnClick = () => {
    if (!pin) {
      const newPin = Math.floor(1000 + Math.random() * 9000).toString()
      setPin(newPin)
      mixpanel.track('Clicou para gerar uma pin para qr code')
    }
    setHidePin(!hidePin)
  }

  const handleOnCopyPasteClick = () => {
    if (!copyPix && !!pixQrCodeValue) {
      setCopyPix(true)
      navigator.clipboard.writeText(pixQrCodeValue)
      mixpanel.track('Clicou em pix copia e colca')
      setTimeout(() => {
        setCopyPix(false)
      }, 5000)
    }
  }

  function Loading() {
    return (
      <div className="flex flex-col items-center justify-center text-neutral-n4">
        <Spinner color="primary-p-0" className="mt-10" />
      </div>
    )
  }

  return (
    <Modal
      title="QR Code"
      closeButtonHidden
      visible={visible}
      onClose={onClose}
    >
      {!!isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col py-4">
          <div className="flex h-full flex-col items-center justify-center gap-4">
            <div className="flex flex-col items-center justify-center gap-4">
              <Button
                intent="primary"
                className="w-full justify-center"
                text={`${hidePin ? 'Ver' : 'Esconder'} o PIN`}
                onClick={handleOnClick}
              />
              <div className="rounded-lg p-4 shadow">
                <div className="px-2 pb-4 text-lg font-thin">
                  Informe o seu PIN para o atendente
                </div>
                <div className="flex items-center justify-center">
                  <input
                    className="mx-2 flex h-16 w-12 items-center rounded-lg border text-center text-3xl font-bold"
                    disabled
                    value={hidePin ? '*' : pin.split('')[0]}
                  ></input>
                  <input
                    className="mx-2 flex h-16 w-12 items-center rounded-lg border text-center text-3xl font-bold"
                    disabled
                    value={hidePin ? '*' : pin.split('')[1]}
                  ></input>
                  <input
                    className="mx-2 flex h-16 w-12 items-center rounded-lg border text-center text-3xl font-bold"
                    disabled
                    value={hidePin ? '*' : pin.split('')[2]}
                  ></input>
                  <input
                    className="mx-2 flex h-16 w-12 items-center rounded-lg border text-center text-3xl font-bold"
                    disabled
                    value={hidePin ? '*' : pin.split('')[3]}
                  ></input>
                </div>
              </div>
            </div>

            <div className="relative rounded-lg p-4 shadow">
              <QRCodeSVG
                value={`${pixQrCodeValue}`}
                size={200}
                opacity={!!pixQrCodeValue ? 1 : 0.1}
              />
              {!pixQrCodeValue && (
                <Spinner
                  color="primary-p-0"
                  className="absolute bottom-1/2 left-0 right-0 top-1/2 m-auto"
                />
              )}
            </div>
            {pixQrCodeValue && (
              <Button
                intent="secondary"
                className="justify-center"
                text={`${!copyPix ? 'Pix Copiar e Colar' : 'Copiado!'}`}
                onClick={handleOnCopyPasteClick}
              />
            )}
          </div>
        </div>
      )}
    </Modal>
  )
}
