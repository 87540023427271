import mixpanel from 'mixpanel-browser'
import { Checkbox } from '@ui/checkbox'
import { EstablishmentAlert } from '@ui/establishment-alert'
import { Icon } from '@ui/icon'
import { Modal } from '@ui/modal'
import { useFilters } from '@hooks/use-filters'
import { AllergenName, allergenNames } from '@models/allergen'
import { Product } from '@models/product'
import { convertAllergenToIconName } from '@utils/icon/convert-allergen-to-icon-name'
import { locale } from '@utils/locale'
import { isNilOrEmpty } from '@utils/logic/is-nil-or-empty'

type AllergensModalProps = {
  products?: Product[]
  visible: boolean
  onClose: () => void
}

export function AllergensFilterModal({
  products,
  visible,
  onClose,
}: AllergensModalProps) {
  const {
    allergens: selectedAllergens,
    toggleAllergen,
    // toggleStamps,
    // stamps,
  } = useFilters()
  const allergensFromProducts = products
    ?.filter((p: Product) => !isNilOrEmpty(p.allergens) && !!p?.allergensEnable)
    ?.flatMap((p: Product) => p.allergens)
    ?.filter(
      (allergen, index, allergens) => allergens.indexOf(allergen) === index,
    )
    ?.map((allergen) => allergen?.name)

  const getSelectedAllergensAndTrackToMixPanel = (
    checked: boolean,
    allergen: AllergenName,
  ) => {
    let allergensSelected = selectedAllergens
    if (checked) allergensSelected = [...selectedAllergens, allergen]
    else
      allergensSelected = [...selectedAllergens.filter((x) => x !== allergen)]
    mixpanel.track('Escolheu alérgeno para restrição', {
      selectedAllergens: allergensSelected,
    })
  }

  const handleOnAllergenChange = (checked: boolean, allergen: AllergenName) => {
    toggleAllergen(allergen)
    getSelectedAllergensAndTrackToMixPanel(checked, allergen)
  }

  // const handleOnStampChange = (stamp: FilterStamps) => {
  //   toggleStamps(stamp)
  // }
  // const StampsContainer = () => {
  //   return (
  //     <div className="flex flex-col">
  //       <h3 className="mx-4 text-lg font-bold leading-6 text-neutral-n2">
  //         Selos
  //       </h3>

  //       {/* <p className="mx-4 mt-2 text-sm font-medium leading-4 text-neutral-n4">
  //         Ao habilitar um selo, os itens do cardápio que não o contenham serão
  //         ocultados
  //       </p> */}

  //       <div className="mt-[22px] flex flex-col gap-0">
  //         <div className="flex h-[52px] justify-between bg-neutral-n12 p-4">
  //           <div className="flex items-center gap-2 text-base text-neutral-n2">
  //             Todos os itens
  //           </div>
  //           <Checkbox
  //             checked={
  //               stamps.promotionSelected &&
  //               stamps.moreOrdersSelected &&
  //               stamps.expressSelected &&
  //               stamps.withoutStampSelected
  //             }
  //             halfChecked={
  //               Object.values(stamps).some((selected) => !!selected) &&
  //               Object.values(stamps).some((selected) => !selected)
  //             }
  //             onChange={(checked) => {
  //               stamps.promotionSelected = checked
  //               stamps.moreOrdersSelected = checked
  //               stamps.expressSelected = checked
  //               stamps.withoutStampSelected = checked
  //               handleOnStampChange(stamps)
  //             }}
  //           />
  //         </div>
  //         <div className="flex h-[52px] justify-between bg-neutral-n12 p-4">
  //           <div className="flex items-center gap-2 text-base text-neutral-n2">
  //             <Icon name="discount2" color="neutral-n4" />
  //             Promoção
  //           </div>
  //           <Checkbox
  //             checked={stamps.promotionSelected}
  //             onChange={(checked) => {
  //               stamps.promotionSelected = checked
  //               handleOnStampChange(stamps)
  //             }}
  //           />
  //         </div>
  //         <div className="flex h-[52px] justify-between bg-neutral-n12 p-4">
  //           <div className="flex items-center gap-2 text-base text-neutral-n2">
  //             <Icon name="star" color="neutral-n4" />
  //             Mais Pedidos
  //           </div>
  //           <Checkbox
  //             checked={stamps.moreOrdersSelected}
  //             onChange={(checked) => {
  //               stamps.moreOrdersSelected = checked
  //               handleOnStampChange(stamps)
  //             }}
  //           />
  //         </div>
  //         <div className="flex h-[52px] justify-between bg-neutral-n12 p-4">
  //           <div className="flex items-center gap-2 text-base text-neutral-n2">
  //             <Icon name="clock-bolt" color="neutral-n4" />
  //             Express
  //           </div>
  //           <Checkbox
  //             checked={stamps.expressSelected}
  //             onChange={(checked) => {
  //               stamps.expressSelected = checked
  //               handleOnStampChange(stamps)
  //             }}
  //           />
  //         </div>
  //         <div className="flex h-[52px] justify-between bg-neutral-n12 p-4">
  //           <div className="flex items-center gap-2 text-base text-neutral-n2">
  //             <Icon
  //               name="clock-bolt"
  //               color="neutral-n4"
  //               className="invisible"
  //             />
  //             Sem selo
  //           </div>
  //           <Checkbox
  //             checked={stamps?.withoutStampSelected}
  //             onChange={(checked) => {
  //               stamps.withoutStampSelected = checked
  //               handleOnStampChange(stamps)
  //             }}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  const AllergensContainer = () => {
    return (
      <div className="flex flex-col">
        <h3 className="mx-4 text-lg font-bold leading-6 text-neutral-n2">
          Alergias ou intolerância
        </h3>

        {/* <p className="mx-4 mt-2 text-sm font-medium leading-4 text-neutral-n4">
          Ao desabilitar um alergênico, os itens do cardápio que o contenham
          serão ocultados
        </p> */}

        <div className="mt-[22px] flex flex-col gap-1">
          {allergenNames
            .filter((a) => allergensFromProducts?.includes(a))
            .map((allergen) => (
              <div
                key={`div-${allergen}`}
                className="flex h-[52px] justify-between bg-neutral-n12 p-4"
              >
                <div className="flex items-center gap-2 text-base text-neutral-n2">
                  <Icon
                    name={convertAllergenToIconName(allergen)}
                    color="neutral-n4"
                  />
                  {locale.allergen.translate(allergen)}
                </div>

                <Checkbox
                  checked={!!selectedAllergens.includes(allergen)}
                  onChange={(checked) =>
                    handleOnAllergenChange(checked, allergen)
                  }
                />
              </div>
            ))}
        </div>
      </div>
    )
  }

  return (
    <Modal
      title="Filtros"
      labelCloseButton="Aplicar"
      visible={visible && !!allergensFromProducts?.length}
      onClose={onClose}
    >
      <div className="flex flex-col justify-between gap-4 py-4">
        <div className="flex flex-col gap-4">
          {/* <StampsContainer /> */}
          <AllergensContainer />
        </div>
        <EstablishmentAlert />
      </div>
    </Modal>
  )
}
