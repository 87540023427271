import { useState } from 'react'
import mixpanel from 'mixpanel-browser'
import T from 'ramda/src/T'
import always from 'ramda/src/always'
import cond from 'ramda/src/cond'
import equals from 'ramda/src/equals'
import { Currency } from '@ui/currency'
import { EstablishmentAlert } from '@ui/establishment-alert'
import { Icon } from '@ui/icon'
import { Img } from '@ui/img'
import { Modal } from '@ui/modal'
import { useFilters } from '@hooks/use-filters'
import { Allergen } from '@models/allergen'
import { Complement, Option, OptionTemp } from '@models/complement'
import { Product } from '@models/product'
import { convertAllergenToIconName } from '@utils/icon/convert-allergen-to-icon-name'
import { compareByOrder } from '@utils/list/sort-by-prop'
import { locale } from '@utils/locale'
import { cn } from '@utils/styles'
import {
  ProductExtraData,
  ProductListItem,
} from '../product-list/product-list-item'
import { OptionDetailsModal } from './option-details-modal'
import { ProductImageModal } from './product-image-modal'

const renderServesText = cond([
  [equals(2), always(`Para ${locale.number.toWord(2)}`)],
  [equals(3), always(`Para ${locale.number.toWord(3)}`)],
  [equals(4), always(`Para ${locale.number.toWord(4)}`)],
  [equals(5), always(`Para ${locale.number.toWord(5)}`)],
  [equals(6), always(`Para ${locale.number.toWord(6)}`)],
  [equals(7), always(`Para ${locale.number.toWord(7)}`)],
  [equals(8), always(`Para ${locale.number.toWord(8)}`)],
  [equals(9), always(`Para ${locale.number.toWord(9)}`)],
  [equals(10), always(`Para ${locale.number.toWord(10)}`)],
  [T, always('Individual')],
])

type ProductDetailsModalProps = {
  product?: Product
  allProducts?: Product[]
  complements?: Complement[]
  visible: boolean
  onClose: () => void
}

export function ProductDetailsModal({
  product,
  allProducts,
  complements,
  visible = false,
  onClose = () => {},
}: ProductDetailsModalProps) {
  const { selectedOption, selectOption } = useFilters()

  const [imgModalOpen, setImgModalOpen] = useState(false)
  const handleOnProductImageClick = () => {
    setImgModalOpen(() => true)
    mixpanel.track('Clicou para expandir imagem do Produto', { product })
  }

  const handleOnOptionClick = (option: Option) => {
    selectOption(option)
    mixpanel.track(`Clicou em opção`, {
      option,
    })
  }

  return (
    <Modal
      closeButtonHidden
      title="Produto"
      visible={visible}
      onClose={onClose}
    >
      {!!product && (
        <>
          <div className="flex flex-col">
            {!!product.imageUrl && (
              <Img
                className="w-full"
                // className="h-[201px] w-full object-cover"
                src={product.imageUrl}
                isThumbnail={false}
                width={390}
                height={201}
                autoGravity
                quality="auto:best"
                alt="imagem do produto"
                onClick={handleOnProductImageClick}
              />
            )}

            {!product.available && (
              <div className="flex h-8 items-center justify-center bg-neutral-n10 text-sm font-bold text-neutral-n4">
                Indisponível
              </div>
            )}

            <div className="bg-neutral-n12 p-4">
              <div className="flex gap-1">
                {!!product.pdvCode && (
                  <h1 className="text-xl font-bold leading-6 text-neutral-n6">
                    {product.pdvCode}
                  </h1>
                )}
                <h1 className="text-xl font-bold leading-6 text-neutral-n2">
                  {product.name}
                </h1>
              </div>

              <p className="mt-2 font-medium leading-6 text-neutral-n6">
                {product.description}
              </p>

              {!!product.available && (
                <div className="mt-4 py-2">
                  <p className="text-xs font-medium tracking-[0.02rem] text-neutral-n4">
                    {renderServesText(product.serves)}
                  </p>
                  <div className="mt-2 flex flex-col gap-1">
                    {!!ProductExtraData(product, allProducts, complements)
                      .showTextFromPrice && (
                      <span className="text-sm font-medium text-neutral-n8">
                        a partir de
                      </span>
                    )}
                    <div className="flex items-center gap-2">
                      <Currency
                        className={cn(
                          'text-base font-medium text-neutral-n4',
                          (!!product?.stamps?.promotion?.enable &&
                            'font-normal line-through decoration-1') ||
                            '',
                        )}
                      >
                        {
                          ProductExtraData(product, allProducts, complements)
                            .price
                        }
                      </Currency>

                      {!!product?.stamps?.promotion?.enable && (
                        <Currency className="font-bold text-neutral-n6">
                          {product.stamps?.promotion?.price || 0}
                        </Currency>
                      )}
                    </div>
                  </div>
                  {(!!product?.stamps?.promotion?.enable ||
                    !!product?.stamps?.moreOrders?.enable ||
                    !!product?.stamps?.express?.enable) && (
                    <div className="mt-2 flex gap-2">
                      {!!product?.stamps?.promotion?.enable && (
                        <div className="flex items-center gap-1 rounded bg-neutral-n11 px-2 py-1">
                          <Icon name="discount2" color="neutral-n6" size={16} />
                          <span className="text-xs font-medium text-neutral-n6">{`${product?.stamps?.promotion?.discount?.toFixed()}% off`}</span>
                        </div>
                      )}
                      {!!product?.stamps?.moreOrders?.enable && (
                        <div className="flex items-center gap-1 rounded bg-neutral-n11 px-2 py-1">
                          <Icon name="star" color="neutral-n6" size={16} />
                          <span className="text-xs font-medium text-neutral-n6">
                            Mais vendido
                            {/* {`Top ${(product?.stamps?.moreOrders.order || 0) + 1}`} */}
                          </span>
                        </div>
                      )}
                      {!!product?.stamps?.express?.enable && (
                        <div className="flex items-center gap-1 rounded bg-neutral-n11 px-2 py-1">
                          <Icon
                            name="clock-bolt"
                            color="neutral-n6"
                            size={16}
                          />
                          <span className="text-xs font-medium text-neutral-n6">{`até 15 min`}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>

            {!!product.allergens?.length && !!product.allergensEnable && (
              <div className="flex flex-row gap-2 p-4">
                {!!product.allergens.filter((a) => !a.mayContainTraces)
                  .length && (
                  <ContainTraces
                    allergens={product.allergens.filter(
                      (a) => !a.mayContainTraces,
                    )}
                    title="Contém"
                  />
                )}
                {!!product.allergens.filter((a) => a.mayContainTraces)
                  .length && (
                  <ContainTraces
                    allergens={product.allergens.filter(
                      (a) => a.mayContainTraces,
                    )}
                    title="Pode conter traços"
                  />
                )}
              </div>
            )}

            <div className="flex-1" />

            <EstablishmentAlert className="mx-4 mt-[22px] pb-4" />
          </div>

          {!!product.available &&
            !!product.linkedComplements?.length &&
            !!complements?.length && (
              <div className="flex flex-col gap-1 divide-y divide-neutral-n10 bg-neutral-n12 pt-2">
                {product.linkedComplements
                  ?.reverse()
                  .sort(compareByOrder)
                  .map((c, complementIndex) => {
                    const complement = ProductExtraData(
                      product,
                      allProducts,
                      complements,
                    ).complements?.find((x) => x._id === c.complementId)
                    if (complement) {
                      complement.options = [
                        ...(complement.options?.filter((x) =>
                          product.linkedComplements
                            ?.reverse()
                            .sort(compareByOrder)
                            ?.some((lc) =>
                              lc.optionsIds.some((o) => o === x._id),
                            ),
                        ) || []),
                      ]
                    }
                    return (
                      !!complement?.options?.length && (
                        <div
                          key={complementIndex}
                          className="flex flex-col gap-2"
                        >
                          <div className="flex h-[72px] flex-col justify-center gap-1 bg-neutral-n11 px-4">
                            <span className="font-bold text-neutral-n4">
                              {complement.name}
                            </span>
                            <div className="flex gap-2">
                              {/* {!!complement.required && (
                              <span className="w-[73px] rounded bg-neutral-n4 px-1 text-xs font-medium text-neutral-n12">
                                Obrigatório
                              </span>
                            )} */}
                              <span className="rounded text-xs font-medium text-neutral-n6">
                                {((!!complement.min &&
                                  complement.min === complement.max) ||
                                  (!complement.min &&
                                    complement.max === 1)) && (
                                  <span>escolha {complement.max}</span>
                                )}
                                {!!complement.min &&
                                  complement.min < complement.max && (
                                    <span>
                                      escolha entre {complement.min} e{' '}
                                      {complement.max}
                                    </span>
                                  )}
                                {!complement.min && complement.max > 1 && (
                                  <span>escolha até {complement.max}</span>
                                )}
                              </span>
                            </div>
                          </div>

                          <div className="flex flex-col gap-2 divide-y divide-neutral-n11">
                            {complement.options?.map((o, oIndex) => (
                              <ProductListItem
                                allProducts={allProducts}
                                complements={complements}
                                key={oIndex}
                                className="px-4"
                                showPlus={
                                  ProductExtraData(
                                    product,
                                    allProducts,
                                    complements,
                                  ).showTextFromPrice &&
                                  !!o.productId &&
                                  !!(o as OptionTemp).isPriceIncrease
                                }
                                product={
                                  {
                                    available: o.available,
                                    name: o.name,
                                    description: o.description,
                                    price: o.priceTable,
                                    imageUrl: o.imageUrl,
                                    allergens: o.allergens,
                                    allergensEnable: !!o.allergensEnable,
                                  } as Product
                                }
                                onClick={() => handleOnOptionClick(o)}
                              />
                            ))}
                          </div>
                        </div>
                      )
                    )
                  })}
              </div>
            )}

          {!!product.imageUrl && (
            <ProductImageModal
              visible={imgModalOpen}
              onClose={() => setImgModalOpen(() => false)}
              imageUrl={product.imageUrl}
            />
          )}

          <OptionDetailsModal
            option={selectedOption}
            visible={!!selectedOption}
            onClose={() => selectOption(undefined)}
          />
        </>
      )}

      {/* <ProductActionButtons price={price} /> */}
    </Modal>
  )
}

export const ContainTraces = ({
  title,
  allergens,
}: {
  title: string
  allergens: Allergen[]
}) => {
  return (
    <div className="flex basis-1/2 flex-col gap-2">
      <p className="text-xs font-medium tracking-[0.02rem]">{title}:</p>
      {allergens.map((a) => (
        <p
          key={a.name}
          className="flex h-6 items-center gap-2 text-sm font-medium leading-4 text-neutral-n6"
        >
          <Icon
            name={convertAllergenToIconName(a.name)}
            color="neutral-n6"
            size={16}
          />

          {locale.allergen.translate(a.name)}
        </p>
      ))}
    </div>
  )
}
