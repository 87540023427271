import { type ComponentPropsWithRef } from 'react'
import { cx } from 'cva'
import { Img } from '@ui/img'
import { cn } from '@utils/styles'
import { Color } from '@config/colors'
import { Icon, IconName } from './icon'

type CardProps = ComponentPropsWithRef<'div'> & {
  title?: string
  subtitle?: string
  classNameTitle?: string
  classNameSubtitle?: string
  iconNameLeft?: IconName
  iconColorLeft?: Color
  iconsSizeLeft?: number
  iconName?: IconName
  iconColor?: Color
  iconsQuantity?: number
  figureUrl?: string
  hidden?: boolean
}

export const Card = ({
  className = '',
  title = '',
  subtitle = '',
  classNameTitle = '',
  classNameSubtitle = '',
  iconNameLeft,
  iconColorLeft,
  iconsSizeLeft = 24,
  iconName,
  iconColor,
  iconsQuantity = 1,
  children = null,
  figureUrl = '',
  hidden = false,
  ...otherProps
}: CardProps) => {
  return (
    <div
      className={cx(
        cn('shadow-card rounded-2xl bg-neutral-n12 p-6', className),
        hidden ? 'hidden' : ''
      )}
      {...otherProps}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center gap-2">
            {!!figureUrl && (
              <figure className="overflow-hidden rounded-full">
                <Img src={figureUrl} alt="Image" width={64} height={64} />
              </figure>
            )}

            {!!iconNameLeft && (
              <Icon
                name={iconNameLeft}
                color={iconColorLeft}
                size={iconsSizeLeft}
              />
            )}

            {!!title && (
              <h1
                className={cn(
                  'text-2xl font-medium text-neutral-n4',
                  classNameTitle
                )}
              >
                {title}
              </h1>
            )}
          </div>
          <div className="flex gap-2">
            {!!iconName &&
              [...Array(iconsQuantity)].map((value: undefined, key: number) => (
                <Icon key={key} name={iconName} color={iconColor} />
              ))}
          </div>
        </div>
        {!!subtitle && (
          <h2
            className={cx(
              'text-base font-medium text-neutral-n8',
              classNameSubtitle
            )}
          >
            {subtitle}
          </h2>
        )}
      </div>

      {children}
    </div>
  )
}
