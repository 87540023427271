import { cloneElement } from 'react'
import {
  IconClockBolt,
  IconDiscount2,
  IconStar,
  IconProps as TablerIconsProps,
} from '@tabler/icons-react'
import {
  IconAdjustments,
  IconBrandPeanut,
  IconChevronDown,
  IconChevronLeft,
  IconEggCracked,
  IconFish,
  IconLoader2,
  IconMilk,
  IconSearch,
  IconX,
  IconQrcode,
  IconBuildingStore,
  IconPhone,
  IconMapPin,
  IconCalendarTime,
  IconCopy,
  IconSoupOff,
  IconSoup,
} from '@tabler/icons-react'
import { colors } from '@config/colors'
import { Almonds } from './almonds'
import { Dyes } from './dyes'
import { Gluten } from './gluten'
import { Lactose } from './lactose'
import { Nuts } from './nuts'
import { Shrimp } from './shrimp'
import { Soy } from './soy'

const icon = {
  'building-store': <IconBuildingStore />,
  'calendar-time': <IconCalendarTime />,
  'chevron-down': <IconChevronDown />,
  'chevron-left': <IconChevronLeft />,
  'clock-bolt': <IconClockBolt />,
  'map-pin': <IconMapPin />,
  'qr-code': <IconQrcode />,
  'soup-off': <IconSoupOff />,
  soup: <IconSoup />,
  adjustments: <IconAdjustments />,
  almonds: <Almonds />,
  copy: <IconCopy />,
  close: <IconX />,
  discount2: <IconDiscount2 />,
  dyes: <Dyes />,
  egg: <IconEggCracked />,
  fish: <IconFish />,
  gluten: <Gluten />,
  lactose: <Lactose />,
  milk: <IconMilk />,
  nuts: <Nuts />,
  peanuts: <IconBrandPeanut />,
  phone: <IconPhone />,
  search: <IconSearch />,
  shrimp: <Shrimp />,
  soy: <Soy />,
  spinner: <IconLoader2 />,
  star: <IconStar />,
}

export type IconName = keyof typeof icon

export type IconProps = TablerIconsProps & {
  color?: keyof typeof colors
  name: IconName
}

export const Icon = ({
  color = 'neutral-n0',
  name,
  size = 24,
  ...otherProps
}: IconProps) => {
  const Component = icon[name]
  return cloneElement(Component, {
    color: colors[color],
    size,
    ...otherProps,
  })
}
