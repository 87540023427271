import { cloneDeep } from 'lodash'
import { isNil } from 'ramda'
import take from 'ramda/src/take'
import { Currency } from '@ui/currency'
import { Icon } from '@ui/icon'
import { Img } from '@ui/img'
import { TextBalloon } from '@ui/text-balloon'
import { Complement, Option, OptionTemp } from '@models/complement'
import type { Product } from '@models/product'
import { convertAllergenToIconName } from '@utils/icon/convert-allergen-to-icon-name'
import { compareBy, compareNumbers } from '@utils/list/sort-by-prop'
import { isNilOrEmpty } from '@utils/logic/is-nil-or-empty'
import { cn } from '@utils/styles'

type ProductCardProps = {
  product: Product
  allProducts?: Product[]
  complements?: Complement[]
  className?: string
  showPlus?: boolean
  onClick?: () => void
}

export function ProductListItem({
  product,
  showPlus = false,
  allProducts,
  complements,
  className,
  onClick,
}: ProductCardProps) {
  const {
    name,
    pdvCode,
    description,
    imageUrl,
    allergens,
    available,
    allergensEnable,
  } = product

  const as = allergens || []
  const firstsAllergens = take(100, as)
  const remainAllergensCount = as.length - firstsAllergens.length

  const productsMoreOrders = allProducts
    ?.filter(
      (x) => !!x.stamps?.moreOrders?.enable, //&& !!x.available
    )
    .sort((a, b) => {
      return !isNil(a.stamps?.moreOrders?.order) &&
        !isNil(b.stamps?.moreOrders?.order)
        ? a.stamps?.moreOrders?.order! - b.stamps?.moreOrders?.order!
        : !isNil(a.stamps?.moreOrders?.order)
          ? -1
          : !isNil(b.stamps?.moreOrders?.order)
            ? 1
            : 0
    })

  return (
    <div
      className={cn('flex flex-col gap-2 bg-neutral-n12 py-4', className)}
      onClick={onClick}
    >
      <div className={cn('flex gap-4')}>
        <div className="flex flex-1 flex-col gap-1 overflow-hidden">
          <div className="flex gap-1">
            {!!pdvCode && (
              <h4 className="text-sm font-bold leading-4 text-neutral-n6">
                {pdvCode}
              </h4>
            )}
            <h4 className="text-sm font-bold leading-4 text-neutral-n2">
              {name}
            </h4>
          </div>
          <div className="flex flex-col gap-2">
            <span className="line-clamp-2 break-words text-sm font-medium leading-4 text-neutral-n6">
              {description}
            </span>

            {!available ? (
              <TextBalloon text="Indisponível" />
            ) : (
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-1">
                  {!!ProductExtraData(product, allProducts, complements)
                    .showTextFromPrice && (
                    <span className="text-sm font-medium text-neutral-n8">
                      a partir de
                    </span>
                  )}
                  <div className="flex gap-2">
                    {!!showPlus && <span>+</span>}
                    <Currency
                      className={cn(
                        'font-medium text-neutral-n4',
                        (!!product?.stamps?.promotion?.enable &&
                          'font-normal line-through decoration-1') ||
                          '',
                      )}
                    >
                      {
                        ProductExtraData(product, allProducts, complements)
                          .price
                      }
                    </Currency>

                    {!!product?.stamps?.promotion?.enable && (
                      <Currency className="font-bold text-neutral-n6">
                        {product.stamps?.promotion?.price || 0}
                      </Currency>
                    )}
                  </div>
                </div>

                {!isNilOrEmpty(allergens) && !!allergensEnable && (
                  <div className="flex items-center gap-1">
                    {firstsAllergens?.map((allergen) => (
                      <Icon
                        key={allergen.name}
                        name={convertAllergenToIconName(allergen.name)}
                        color="neutral-n6"
                        size={20}
                      />
                    ))}

                    {remainAllergensCount > 0 && (
                      <span className="text-sm font-bold leading-4 text-neutral-n6">
                        +{remainAllergensCount}
                      </span>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="relative">
          {(!!imageUrl && (
            <Img
              className="h-24 w-24 rounded"
              src={imageUrl}
              width={96}
              height={96}
              autoGravity={false}
              lazy
              alt={name}
            />
          )) || (
            <div className="flex h-24 w-24 items-center justify-center rounded bg-neutral-n11">
              <Icon name="soup" color="neutral-n10" size={56} />
            </div>
          )}

          {!!product?.stamps?.moreOrders?.enable && (
            <div className="absolute -top-2 right-1 flex h-6 w-6 items-center justify-center rounded-b bg-neutral-n4">
              <span className="text-xs font-bold text-neutral-n12">
                #
                {(productsMoreOrders?.findIndex((x) => x._id === product._id) ||
                  product?.stamps?.moreOrders.order ||
                  0) + 1}
              </span>
            </div>
          )}
        </div>
      </div>

      {!!available &&
        (!!product?.stamps?.promotion?.enable ||
          !!product?.stamps?.moreOrders?.enable ||
          !!product?.stamps?.express?.enable) && (
          <div className="flex gap-1">
            {!!product?.stamps?.promotion?.enable && (
              <div className="flex items-center gap-1 rounded bg-neutral-n11 px-2 py-1">
                <Icon name="discount2" color="neutral-n6" size={16} />
                <span className="text-xs font-medium text-neutral-n6">{`${product?.stamps?.promotion?.discount?.toFixed()}% off`}</span>
              </div>
            )}
            {!!product?.stamps?.moreOrders?.enable && (
              <div className="flex items-center gap-1 rounded bg-neutral-n11 px-2 py-1">
                <Icon name="star" color="neutral-n6" size={16} />
                <span className="text-xs font-medium text-neutral-n6">
                  Mais vendido
                  {/* {`Top ${(product?.stamps?.moreOrders.order || 0) + 1}`} */}
                </span>
              </div>
            )}
            {!!product?.stamps?.express?.enable && (
              <div className="flex items-center gap-1 rounded bg-neutral-n11 px-2 py-1">
                <Icon name="clock-bolt" color="neutral-n6" size={16} />
                <span className="text-xs font-medium text-neutral-n6">{`até 15 min`}</span>
              </div>
            )}
          </div>
        )}
    </div>
  )
}

export const ProductExtraData = (
  product: Product,
  allProducts?: Product[],
  allComplements?: Complement[],
) => {
  const orderMap: { [key: string]: number } = {}
  product.linkedComplements?.forEach((item) => {
    orderMap[item.complementId] = item.order
  })

  const productsComplements = [...(cloneDeep(allComplements) || [])]
    ?.filter((z) =>
      product.linkedComplements?.some((c) => c.complementId === z._id),
    )
    .map((c) => {
      let options = [...(c.options || [])]
      options = [
        ...(options
          ?.map((o) => {
            if (!o.productId) return o
            return (
              [...(cloneDeep(allProducts) || [])]
                ?.filter((p) => p._id === o.productId)
                .map(
                  (p) =>
                    ({
                      _id: o._id,
                      name: p.name,
                      imageUrl: p.imageUrl,
                      pdvCode: p.pdvCode,
                      allergens: p.allergens,
                      allergensEnable: p.allergensEnable,
                      priceTable: o.priceTable || 0,
                      // priceTable:
                      //   o.priceTable === 0 ? 0 : o.priceTable || p.price,
                      available: p.available,
                      productId: o.productId,
                      description: p.description,
                      isPriceIncrease: (o.priceTable || 0) <= p.price,
                    }) as OptionTemp,
                )[0] || ({} as OptionTemp)
            )
          })
          ?.filter((x) =>
            product.linkedComplements?.some((lc) =>
              lc.optionsIds.some((o) => o === x._id),
            ),
          ) || []),
      ]
      c.options = options
      return c
    })
    .sort((a, b) => {
      return !!product.linkedComplements?.length
        ? (orderMap[a._id] || 0) - (orderMap[b._id] || 0)
        : 0
    })

  const complementsWithPrice = productsComplements?.filter(
    (x) => x.required && x.options?.some((o) => o.available && o.priceTable),
    // && x.options?.filter((o) => o.available).every((o) => o.priceTable),
  )

  const optionsMinorPrices =
    cloneDeep(complementsWithPrice)
      ?.map((x) => x.options)
      ?.reduce(
        (a, c) =>
          a?.concat(
            c
              ?.sort(compareBy('priceTable'))
              .map((x) => x.priceTable || 0)?.[0] || 0,
          ),
        new Array<number>(),
      )
      ?.reduce((a, c) => a + c, 0) || 0

  const optionStartPrice = (
    complementsWithPrice
      ?.reduce((a, c) => a.concat(c.options || []), new Array<Option>())
      .reduce((a, c) => a.concat(c.priceTable || 0), new Array<number>()) || [0]
  )
    .filter((price) => price)
    .sort(compareNumbers)[0]
  const showTextFromPrice = !!optionStartPrice
  const price = product.price + optionsMinorPrices || optionStartPrice
  return {
    price,
    showTextFromPrice,
    complements: productsComplements,
  }
}
