import { Category } from '@models/category'
import { Product } from '@models/product'

export const getCategoriesFromProducts = (products: Product[]) =>
  products.reduce<Category[]>((categories, product) => {
    const alreadyInArray = categories.some(
      (c) => c._id === product.category._id
    )
    if (alreadyInArray) {
      return categories
    }

    return [...categories, product.category]
  }, [])
