import { useEffect, useRef } from 'react'
import type { ComponentPropsWithoutRef } from 'react'
import { cx } from 'cva'

type StyledStickyProps = ComponentPropsWithoutRef<'div'> & {
  classNamePinned: string
}

export function StyledSticky({
  className,
  classNamePinned,
  children,
  ...otherProps
}: StyledStickyProps) {
  const ref = useRef(null)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const observer = new IntersectionObserver(
      ([e]) =>
        classNamePinned
          .split(' ')
          .forEach((className) =>
            e.target.classList.toggle(className, e.intersectionRatio < 1)
          ),
      { threshold: [1] }
    )

    observer.observe(ref.current)

    return () => observer.disconnect()
  }, [classNamePinned])

  return (
    <div
      className={cx('sticky top-[-1px]', className)}
      {...otherProps}
      ref={ref}
    >
      {children}
    </div>
  )
}
