import { ComponentPropsWithoutRef, forwardRef, memo } from 'react'
import type { LegacyRef } from 'react'
import mixpanel from 'mixpanel-browser'
import { Icon, IconName } from '@ui/icon'
import { useFilters } from '@hooks/use-filters'
import { Complement } from '@models/complement'
import { Product } from '@models/product'
import { isLastIndex } from '@utils/array/is-last-index'
import { sortByProp, sortByPropWithToLower } from '@utils/list/sort-by-prop'
import { cn } from '@utils/styles'
import { type Color } from '@config/colors'
import { ProductListItem } from './product-list-item'

type ProductSectionProps = ComponentPropsWithoutRef<'div'> & {
  title?: string
  products: Product[]
  allProducts?: Product[]
  complements?: Complement[]
  iconName?: IconName
  iconColor?: Color
  stopProductClick?: boolean
  dontDoAutoSort?: boolean
  isLastCategory?: boolean
  isFromSuggestion?: boolean
}

export const ProductSection = memo(
  forwardRef<LegacyRef<HTMLElement>, ProductSectionProps>(
    (
      {
        title,
        products,
        allProducts,
        complements,
        iconName,
        iconColor = 'neutral-n4',
        stopProductClick = false,
        dontDoAutoSort = false,
        isLastCategory = false,
        isFromSuggestion = false,
        ...otherProps
      },
      ref,
    ) => {
      const { selectProduct } = useFilters()

      const handleOnProductClick = (product: Product) => {
        if (!stopProductClick) {
          selectProduct(product)
          mixpanel.track(
            `Clicou em um produto${
              isFromSuggestion ? ' apartir de sugestão' : ''
            }`,
            {
              product,
            },
          )
        }
      }

      const sortedProducts = sortByProp<Product>(
        'order',
        sortByPropWithToLower('name', products),
      )

      return (
        <section ref={ref as any} {...otherProps}>
          {!!title && (
            <header className="flex h-[72px] items-center gap-2 px-4 py-2">
              {!!iconName && <Icon name={iconName} color={iconColor} />}
              <h3 className="font-bold text-neutral-n4">{title}</h3>
            </header>
          )}

          <div className="flex flex-col gap-2 bg-neutral-n12 px-4">
            {(dontDoAutoSort ? products : sortedProducts).map(
              (product, index) => (
                <div
                  key={product._id}
                  className={cn(
                    'border-b border-neutral-n10 last:border-0',
                    isLastCategory && isLastIndex(index, products)
                      ? 'mb-14'
                      : '',
                  )}
                  onClick={() => handleOnProductClick(product)}
                >
                  <ProductListItem
                    product={product}
                    allProducts={allProducts}
                    complements={complements}
                  />
                </div>
              ),
            )}
          </div>
        </section>
      )
    },
  ),
)
