export const weekDayNames = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
] as const

export const dayOfWeek = weekDayNames[new Date().getDay()]
export const dayOfWeekIndex = new Date().getDay()
export const nextDayOfWeek = weekDayNames[dayOfWeekIndex === (weekDayNames.length - 1) ? 0 : dayOfWeekIndex + 1]

export type WeekDayName = (typeof weekDayNames)[number]

export type BusinessWeekDayInterval = {
  name: string
  startHour: string
  endHour: string
}
export type BusinessWeekDay = {
  name: WeekDayName
  available: boolean
  intervals: Array<BusinessWeekDayInterval>
}
