import { cva } from 'cva'
import mixpanel from 'mixpanel-browser'
import { useToggle } from 'usehooks-ts'
// import { Icon } from '@ui/icon';
import { Button } from '@ui/icon/button'
import { IconButton } from '@ui/icon/icon-button'
import { StyledSticky } from '@ui/styled-sticky'
import { useFilters } from '@hooks/use-filters'
import { useScrollHash } from '@hooks/use-scroll-hash'
import { Category } from '@models/category'
import { Product } from '@models/product'
import { cn } from '@utils/styles'
import { SearchInput } from '../search-input'
import { AllergensFilterModal } from './allergens-filter-modal'
import { CategoryListModal } from './category-list-modal'
import { modal, useVisibleModals } from './use-visible-modals'

const contentVisibleIconVariants = cva(['transition-transform'], {
  variants: {
    visible: {
      true: 'rotate-180',
    },
  },
  defaultVariants: {
    visible: false,
  },
})

type FiltersProps = {
  categories?: Category[]
  products?: Product[]
  hidden?: boolean
}

export function Filters({
  categories = [],
  products = [],
  hidden = false,
}: FiltersProps) {
  const { includes, toggle } = useVisibleModals()
  const [showSearchInput, toggleShowSearchInput] = useToggle(false)
  const [categoryCollapsed, toggleCategoryCollapsed] = useToggle(false)

  const { setSearchTerm, stamps, toggleStamps } = useFilters() // allergens,
  const { activeHash } = useScrollHash()

  if (hidden) {
    return null
  }
  // const allergensSelectedAmount = allergens.length

  const handleOnCategoryClick = () => {
    toggle(modal.category)
    toggleCategoryCollapsed()
    mixpanel.track('Clicou para mudar de categoria')
  }

  const handleOnCategoryClose = () => {
    toggle(modal.category)
    toggleCategoryCollapsed()
    mixpanel.track('Fechou escolha de categoria')
  }

  const handleOnAllergensModalClick = () => {
    toggle(modal.allergens)
    // mixpanel.track('Clicou em restrições por alérgeno')
    mixpanel.track('Clicou em Filtrar')
  }

  const handleOnAllergensModalClose = () => {
    toggle(modal.allergens)
    // mixpanel.track('Fechou restrições por alérgeno')
    mixpanel.track('Fechou Filtrar')
  }

  return (
    <>
      <StyledSticky
        className="z-10 flex h-[72px] !bg-neutral-n12 text-neutral-n4"
        // className="z-10 flex h-[112px] !bg-neutral-n12 text-neutral-n4"
        classNamePinned="bg-neutral-n12 shadow-header"
      >
        <div className="flex w-full flex-col justify-center">
          {!!showSearchInput && (
            <div className="flex h-[72px] w-full items-center justify-center gap-4 p-4">
              <SearchInput />
              <Button
                intent="tertiary"
                text="Cancelar"
                onClick={() => {
                  setSearchTerm('')
                  toggleShowSearchInput()
                }}
              />
            </div>
          )}

          {!showSearchInput && (
            <div className="flex h-[72px] w-full items-center justify-between p-4">
              <div
                className="flex items-center gap-2 font-medium text-primary-p-0"
                onClick={handleOnCategoryClick}
              >
                {/* <Icon name="chevron-down" color="neutral-n4" /> */}
                <IconButton
                  className={contentVisibleIconVariants({
                    visible: categoryCollapsed,
                  })}
                  intent="tertiary"
                  iconName="chevron-down"
                  type="button"
                />
                {activeHash || 'Categorias'}
              </div>

              <div className="flex items-center justify-center gap-4">
                <Button
                  intent="tertiary"
                  text="Filtrar"
                  onClick={handleOnAllergensModalClick}
                />
                <div className="h-6 border-r border-neutral-n10" />

                {/* <div className="relative">
            <Icon
              name="adjustments"
              color="neutral-n4"
              onClick={handleOnAllergensModalClick}
            />

            {allergensSelectedAmount > 0 && (
              <span className="absolute -top-1 left-4 flex h-4 w-4 items-center justify-center rounded-full border-2 border-neutral-n11 bg-primary-p-0 text-xs font-bold text-neutral-n12">
                {allergensSelectedAmount}
              </span>
            )}
          </div> */}

                <IconButton
                  intent="tertiary"
                  iconName="search"
                  color="primary-p-0"
                  type="button"
                  onClick={toggleShowSearchInput}
                />
              </div>
            </div>
          )}
          {products.some(
            (x) =>
              !!x?.stamps?.promotion?.enable ||
              !!x?.stamps?.moreOrders?.enable ||
              !!x?.stamps?.express?.enable,
          ) && (
            <div
              className={cn(
                'hidden h-10 items-center gap-4 overflow-x-auto bg-neutral-n12 px-3 [-ms-overflow-style:none] [scrollbar-width:none] max-md:w-screen [&::-webkit-scrollbar]:hidden',
              )}
            >
              <Button
                size="xsmall"
                className="whitespace-nowrap rounded-full border border-solid border-primary-p-0 p-2 text-sm"
                intent={stamps.allStampSelected ? 'primary' : 'tertiary'}
                text="Todos"
                onClick={() => {
                  stamps.allStampSelected = true
                  stamps.promotionSelected = true
                  stamps.moreOrdersSelected = true
                  stamps.expressSelected = true
                  stamps.withoutStampSelected = true
                  toggleStamps(stamps)
                }}
              />
              {products.some((x) => !!x?.stamps?.promotion?.enable) && (
                <Button
                  iconLeft="star"
                  size="xsmall"
                  sizeNumber={16}
                  className="whitespace-nowrap rounded-full border border-solid border-primary-p-0 p-2 text-sm"
                  intent={
                    stamps?.promotionSelected && !stamps.allStampSelected
                      ? 'primary'
                      : 'tertiary'
                  }
                  text="Promoção"
                  onClick={() => {
                    stamps.allStampSelected = false
                    stamps.promotionSelected = true
                    stamps.moreOrdersSelected = false
                    stamps.expressSelected = false
                    stamps.withoutStampSelected = false
                    toggleStamps(stamps)
                  }}
                />
              )}
              {products.some((x) => !!x?.stamps?.moreOrders?.enable) && (
                <Button
                  iconLeft="star"
                  size="xsmall"
                  sizeNumber={16}
                  className="whitespace-nowrap rounded-full border border-solid border-primary-p-0 p-2 text-sm"
                  intent={
                    stamps?.moreOrdersSelected && !stamps.allStampSelected
                      ? 'primary'
                      : 'tertiary'
                  }
                  text="Mais Pedidos"
                  onClick={() => {
                    stamps.allStampSelected = false
                    stamps.promotionSelected = false
                    stamps.moreOrdersSelected = true
                    stamps.expressSelected = false
                    stamps.withoutStampSelected = false
                    toggleStamps(stamps)
                  }}
                />
              )}
              {products.some((x) => !!x?.stamps?.express?.enable) && (
                <Button
                  iconLeft="star"
                  size="xsmall"
                  sizeNumber={16}
                  className="whitespace-nowrap rounded-full border border-solid border-primary-p-0 p-2 text-sm"
                  intent={
                    stamps?.expressSelected && !stamps.allStampSelected
                      ? 'primary'
                      : 'tertiary'
                  }
                  text="Express"
                  onClick={() => {
                    stamps.allStampSelected = false
                    stamps.promotionSelected = false
                    stamps.moreOrdersSelected = false
                    stamps.expressSelected = true
                    stamps.withoutStampSelected = false
                    toggleStamps(stamps)
                  }}
                />
              )}
            </div>
          )}
        </div>
      </StyledSticky>

      <CategoryListModal
        categories={categories}
        visible={includes(modal.category)}
        onClose={handleOnCategoryClose}
      />

      <AllergensFilterModal
        products={products}
        visible={includes(modal.allergens)}
        onClose={handleOnAllergensModalClose}
      />
    </>
  )
}
