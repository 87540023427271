import { useCallback, useMemo, useReducer } from 'react'
import type { Reducer } from 'react'
import without from 'ramda/src/without'

type State = string[]

type Action = {
  type: 'toggle-item'
  payload: string
}

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case 'toggle-item': {
      const item = action.payload
      const list = state

      const listContainsItem = list.some((i) => i === item)

      if (listContainsItem) {
        return without([item], list)
      } else {
        return [...list, item]
      }
    }
    default:
      throw Error(`Unexpected action ${action}`)
  }
}

export const useToggableItemsList = (initialState: State = []) => {
  const [list, dispatch] = useReducer(reducer, initialState)

  const memoizedList = useMemo(() => list, [list])

  const toggle = useCallback(
    (item: string) => dispatch({ type: 'toggle-item', payload: item }),
    [dispatch]
  )

  const includes = useCallback(
    (item: string) => memoizedList.some((i) => i === item),
    [memoizedList]
  )

  return {
    list: memoizedList,
    toggle,
    includes,
  }
}
