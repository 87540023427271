import { Img } from '@ui/img'
import { Modal } from '@ui/modal'

type ProductImageModalProps = {
  imageUrl: string
  visible: boolean
  onClose: () => void
}

export function ProductImageModal({
  imageUrl,
  visible,
  onClose,
}: ProductImageModalProps) {
  return (
    <Modal closeButtonHidden visible={visible} onClose={onClose} title=" ">
      <Img
        className="w-full"
        src={imageUrl}
        doResize={false}
        alt="imagem expandida do produto"
      />
    </Modal>
  )
}
